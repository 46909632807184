import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faPen, faExternalLink } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import withErrorBoundary from '../../hoc/withErrorBoundary';
import { Card, CardContent, Typography, Box, IconButton, Rating } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledCard = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius * 2,
  boxShadow: theme.shadows[2],
  transition: 'box-shadow 0.2s ease-in-out',
  '&:hover': {
    boxShadow: theme.shadows[4],
  },
}));

const ActionButton = styled(IconButton)(({ theme }) => ({
  padding: theme.spacing(1),
  '&.edit-button': {
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
  '&.product-button': {
    color: theme.palette.success.main,
    '&:hover': {
      backgroundColor: theme.palette.success.light,
    },
  },
}));

const ReviewCard = ({ 
  review, 
  isBuyerNameVisible = true, 
  isProductNameVisible = true, 
  isEditClickable = false, 
  isEditVisible = false, 
  onEditClick, 
  isProductClickable = false, 
  isProductVisible = false 
}) => {
  const navigate = useNavigate();

  return (
    <StyledCard>
      <CardContent>
        <Box display="flex" justifyContent="space-between" alignItems="flex-start">
          <Box flex={1}>
            {isProductNameVisible && (
              <Typography variant="h6" gutterBottom>
                {review.product.name}
              </Typography>
            )}
            {isBuyerNameVisible && (
              <Typography variant="subtitle1" gutterBottom>
                {review.buyer.name.split(' ').map(name => name[0]).join(' ... ')}
              </Typography>
            )}
            <Box display="flex" alignItems="center" mb={1.5}>
              <Rating
                value={review.rating}
                readOnly
                precision={1}
                size="small"
              />
              <Typography variant="body2" color="text.secondary" ml={1}>
                ({review.rating}/5)
              </Typography>
            </Box>
          </Box>

          <Box display="flex" gap={1}>
            {isEditClickable && isEditVisible && (
              <ActionButton
                className="edit-button"
                onClick={() => onEditClick(review)}
                size="small"
                title="Değerlendirmeyi Düzenle"
              >
                <FontAwesomeIcon icon={faPen} />
              </ActionButton>
            )}
            {isProductClickable && isProductVisible && (
              <ActionButton
                className="product-button"
                onClick={() => navigate(`/product/${review.productId}`)}
                size="small"
                title="Ürüne Git"
              >
                <FontAwesomeIcon icon={faExternalLink} />
              </ActionButton>
            )}
          </Box>
        </Box>

        <Typography variant="body1" color="text.secondary" paragraph>
          {review.comment}
        </Typography>

        <Box display="flex" justifyContent="space-between" alignItems="center" mt={2}>
          <Typography variant="caption" color="text.secondary">
            Yayınlanma: {new Date(review.createdAt).toLocaleDateString('tr-TR')}
          </Typography>
          {review.updatedAt !== review.createdAt && (
            <Typography variant="caption" color="text.secondary" sx={{ fontStyle: 'italic' }}>
              (Düzenlendi: {new Date(review.updatedAt).toLocaleDateString('tr-TR')})
            </Typography>
          )}
        </Box>
      </CardContent>
    </StyledCard>
  );
};

export default withErrorBoundary(ReviewCard, {
  resetButtonText: 'Reload Review'
}); 