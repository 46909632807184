import axios from "axios";
import ApiResponse from "./ApiResponse";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// Create axios instance with default config
const api = axios.create({
  baseURL: API_BASE_URL,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
});

// // Add response interceptor to transform all responses to ApiResponse
// api.interceptors.response.use((response) =>
//   ApiResponse.fromAxiosResponse(response)
// );

// Add global error handler
api.interceptors.response.use(
  (response) => ApiResponse.fromAxiosResponse(response),
  (error) => {
    // Ensure we have a valid error object
    if (!error) {
      console.error("Unknown error occurred");
      return Promise.reject(
        new ApiResponse({
          success: false,
          message: "An unexpected error occurred",
          status: 500,
        })
      );
    }
    const apiResponse = ApiResponse.fromAxiosError(error);
    console.error(apiResponse);
    return Promise.reject(apiResponse);
  }
);

export const authApi = {
  login: async (email, password) => {
    return await api.post(`/api/auth/buyer/login`, { email, password });
  },

  register: async (userData) => {
    return await api.post(`/api/auth/buyer/register`, userData);
  },

  logout: async () => {
    return await api.post("/api/auth/buyer/logout");
  },

  verifyToken: async () => {
    return await api.get("/api/auth/buyer/verify");
  },

  updateUser: async (id, userData) => {
    return await api.put(`/api/auth/buyer/update/${id}`, userData);
  },
};

export const productsApi = {
  getAllProducts: async () => {
    return await api.get("/api/products/products");
  },

  getProductById: async (id) => {
    const response = await api.get(`/api/products/products/${id}`);
    return response;
  },

  getProductsByQuery: async (query) => {
    const response = await api.get(`/api/products/query/${query}`);
    return response;
  },

  getProductsByCategory: async (categoryId) => {
    const response = await api.get(`/api/products/category/${categoryId}`);
    return response;
  },

  getCoverImageUrl: (id) => {
    return `${API_BASE_URL}/api/products/${id}/coverImage`;
  },

  getProductImageUrl: (id, index) => {
    return `${API_BASE_URL}/api/products/${id}/productImage/${index}`;
  },

  // Seller specific endpoints
  getSellerProducts: async (sellerId = null) => {
    const endpoint = sellerId
      ? `/api/products/seller/${sellerId}`
      : "/api/products/seller";
    const response = await api.get(endpoint);
    return response;
  },
};

export const sellersApi = {
  getSellers: async () => {
    return await api.get("/api/sellers");
  },

  getSellerById: async (id) => {
    return await api.get(`/api/sellers/${id}`);
  },

  getSellerCoverImageUrl: (id) => {
    return `${API_BASE_URL}/api/sellers/${id}/cover-image`;
  },

  getSellerProfileImageUrl: (id) => {
    return `${API_BASE_URL}/api/sellers/${id}/profile-image`;
  },
};

export const ordersApi = {
  createOrder: async (cart) => {
    return await api.post("/api/orders", { cart });
  },

  getBuyerOrders: async () => {
    return await api.get("/api/orders/buyer");
  },

  // updateOrderStatus: async (orderId, status) => {
  //   return await api.put(`/api/orders/${orderId}/status`, { status });
  // },
};

export const wishlistApi = {
  getWishlist: async () => {
    return await api.get("/api/wishlist");
  },

  addToWishlist: async (productId) => {
    return await api.post("/api/wishlist", { productId });
  },

  removeFromWishlist: async (productId) => {
    return await api.delete(`/api/wishlist/${productId}`);
  },
};

export const reviewsApi = {
  getProductReviews: async (productId) => {
    return await api.get(`/api/reviews/product/${productId}`);
  },

  getBuyerReviews: async () => {
    return await api.get("api/reviews/buyer");
  },

  getSellerReviews: async (sellerId) => {
    return await api.get(`/api/reviews/seller/${sellerId}`);
  },

  createReview: async (productId, reviewData) => {
    return await api.post("/api/reviews", {
      productId,
      ...reviewData,
    });
  },

  updateReview: async (reviewId, reviewData) => {
    return await api.put(`/api/reviews/${reviewId}`, reviewData);
  },

  deleteReview: async (reviewId) => {
    return await api.delete(`/api/reviews/${reviewId}`);
  },
};

export const addressApi = {
  getAllAddresses: async () => {
    return await api.get("/api/addresses");
  },

  createAddress: async (addressData) => {
    return await api.post("/api/addresses", addressData);
  },

  updateAddress: async (id, addressData) => {
    return await api.put(`/api/addresses/${id}`, addressData);
  },

  deleteAddress: async (id) => {
    return await api.delete(`/api/addresses/${id}`);
  },
};

export const categoriesApi = {
  getAllCategories: async () => {
    return await api.get("/api/categories");
  },

  getCategoryById: async (id) => {
    return await api.get(`/api/categories/${id}`);
  },

  getCategoryImageUrl: (id) => {
    return `${API_BASE_URL}/api/categories/${id}/image`;
  },
};

export const statsApi = {
  // Product Statistics
  getMostBoughtProducts: async (limit) => {
    const endpoint = limit
      ? `/api/stats/products/most-bought/${limit}`
      : "/api/stats/products/most-bought";
    return await api.get(endpoint);
  },

  // Category Statistics
  getMostBoughtCategories: async (level) => {
    const endpoint =
      level !== undefined
        ? `/api/stats/categories/most-bought/${level}`
        : "/api/stats/categories/most-bought";
    return await api.get(endpoint);
  },

  // Seller Statistics
  getMostSellingSellers: async (limit) => {
    const endpoint = limit
      ? `/api/stats/sellers/most-selling/${limit}`
      : "/api/stats/sellers/most-selling";
    return await api.get(endpoint);
  },

  getSellerAverageRating: async (sellerId) => {
    return await api.get(`/api/stats/seller/rating/${sellerId}`);
  },
};

export const postsApi = {
  // Get all posts of a seller
  getSellerPosts: async (sellerId = null) => {
    const endpoint = sellerId
      ? `/api/posts/seller/${sellerId}`
      : "/api/posts/posts";
    return await api.get(endpoint);
  },

  // Get a specific post by ID
  getPostById: async (id) => {
    return await api.get(`/api/posts/${id}`);
  },

  // Helper method to get post image URLs
  getPostImageUrl: (postId, index) => {
    return `${API_BASE_URL}/api/posts/${postId}/image/${index}`;
  },
};

export default api;
