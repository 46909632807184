import React, { useState } from "react";
import ProductCard from "./cards/ProductCard";
import withErrorBoundary from "../hoc/withErrorBoundary";
import { Box, Grid, Pagination, PaginationItem, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";

const ProductGrid = styled(Grid)(({ theme }) => ({
  "& .MuiGrid-item": {
    height: 450,
  },
}));

const ProductList = ({ products }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const productsPerPage = 12; // Show 12 products per page (3x4 grid)

  // Calculate pagination
  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = products.slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );
  const totalPages = Math.ceil(products.length / productsPerPage);

  const handlePageChange = (event, pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <Stack spacing={4}>
      <ProductGrid container spacing={3} >
        {currentProducts.map((product) => (
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2.4} key={product.id}>
            <ProductCard product={product} />
          </Grid>
        ))}
      </ProductGrid>

      {totalPages > 1 && (
        <Box display="flex" justifyContent="center" my={3}>
          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={handlePageChange}
            color="primary"
            shape="rounded"
            renderItem={(item) => (
              <PaginationItem
                {...item}
                sx={{
                  "&.Mui-selected": {
                    backgroundColor: "primary.main",
                    color: "#FFFFFF !important",
                    "&:hover": {
                      backgroundColor: "primary.dark",
                      color: "#FFFFFF !important",
                    },
                  },
                }}
              />
            )}
          />
        </Box>
      )}
    </Stack>
  );
};

export default withErrorBoundary(ProductList, {
  resetButtonText: "Retry Loading Products",
});
