import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import CitiesJson from "../../constants/turkey";
import withErrorBoundary from "../../hoc/withErrorBoundary";
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
  Checkbox,
  FormControlLabel,
  Button,
  Grid,
  Paper,
  Box,
  InputAdornment,
} from "@mui/material";

const phoneRegExp =
  /^(([\+]?90[-\s]?)?(5)([0-9]{2})\s?([0-9]{3})\s?([0-9]{2})\s?([0-9]{2}))$/;

const AddressValidationSchema = Yup.object().shape({
  title: Yup.string()
    .min(2, "Çok kısa!")
    .max(50, "Çok uzun!")
    .required("Adres başlığı gerekli"),
  fullName: Yup.string()
    .min(2, "Çok kısa!")
    .max(50, "Çok uzun!")
    .required("Ad soyad gerekli"),
  phoneNumber: Yup.string()
    .matches(phoneRegExp, "Geçerli bir Türk telefon numarası giriniz")
    .required("Telefon numarası gerekli"),
  city: Yup.string().required("Şehir seçimi gerekli"),
  district: Yup.string().required("İlçe seçimi gerekli"),
  neighborhood: Yup.string().required("Mahalle gerekli"),
  fullAddress: Yup.string()
    .min(10, "Adres çok kısa!")
    .required("Açık adres gerekli"),
  isDefault: Yup.boolean(),
});

const AddressForm = ({ mode, initialData, onSubmit, onCancel }) => {
  const [districts, setDistricts] = useState([]);

  const updateDistricts = (cityName) => {
    if (cityName) {
      const cityData = CitiesJson[cityName];
      if (cityData) {
        setDistricts(cityData.districts);
      }
    } else {
      setDistricts([]);
    }
  };

  // Initial districts load
  useEffect(() => {
    if (initialData.city) {
      updateDistricts(initialData.city);
    }
  }, [initialData.city]);

  return (
    <Formik
      initialValues={initialData}
      validationSchema={AddressValidationSchema}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(values);
        setSubmitting(false);
      }}
      validateOnChange={true}
      validateOnBlur={true}
    >
      {({
        values,
        setFieldValue,
        isSubmitting,
        errors,
        touched,
        handleChange,
        handleBlur,
      }) => {
        const handleCityChange = (e) => {
          const cityName = e.target.value;
          setFieldValue("city", cityName);
          setFieldValue("district", "");
          updateDistricts(cityName);
        };

        return (
          <Form>
            <Paper
              elevation={0}
              sx={{
                p: 3,
                mb: 3,
                bgcolor: mode === "add" ? "grey.50" : "background.paper",
                border: 1,
                borderColor: mode === "add" ? "grey.300" : "primary.main",
                borderWidth: mode === "edit" ? 2 : 1,
                borderRadius: 2,
                "& .MuiFormLabel-root": {
                  color: mode === "edit" ? "primary.main" : "text.secondary",
                },
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    id="title"
                    name="title"
                    label="Adres Başlığı"
                    value={values.title}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.title && Boolean(errors.title)}
                    helperText={touched.title && errors.title}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    id="fullName"
                    name="fullName"
                    label="Ad Soyad"
                    value={values.fullName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.fullName && Boolean(errors.fullName)}
                    helperText={touched.fullName && errors.fullName}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    id="phoneNumber"
                    name="phoneNumber"
                    label="Telefon Numarası"
                    value={values.phoneNumber}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.phoneNumber && Boolean(errors.phoneNumber)}
                    helperText={touched.phoneNumber && errors.phoneNumber}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">🇹🇷 +90</InputAdornment>
                      ),
                    }}
                    placeholder="5XX XXX XX XX"
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <FormControl
                    fullWidth
                    error={touched.city && Boolean(errors.city)}
                  >
                    <InputLabel id="city-label">Şehir</InputLabel>
                    <Select
                      labelId="city-label"
                      id="city"
                      name="city"
                      value={values.city}
                      label="Şehir"
                      onChange={handleCityChange}
                    >
                      <MenuItem value="">
                        <em>Şehir Seçiniz</em>
                      </MenuItem>
                      {Object.keys(CitiesJson).map((city) => (
                        <MenuItem key={city} value={city}>
                          {city}
                        </MenuItem>
                      ))}
                    </Select>
                    {touched.city && errors.city && (
                      <FormHelperText>{errors.city}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6}>
                  <FormControl
                    fullWidth
                    error={touched.district && Boolean(errors.district)}
                    disabled={!values.city}
                  >
                    <InputLabel id="district-label">İlçe</InputLabel>
                    <Select
                      labelId="district-label"
                      id="district"
                      name="district"
                      value={values.district}
                      label="İlçe"
                      onChange={handleChange}
                    >
                      <MenuItem value="">
                        <em>İlçe Seçiniz</em>
                      </MenuItem>
                      {districts.map((district) => (
                        <MenuItem key={district} value={district}>
                          {district}
                        </MenuItem>
                      ))}
                    </Select>
                    {touched.district && errors.district && (
                      <FormHelperText>{errors.district}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    id="neighborhood"
                    name="neighborhood"
                    label="Mahalle"
                    value={values.neighborhood}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.neighborhood && Boolean(errors.neighborhood)}
                    helperText={touched.neighborhood && errors.neighborhood}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="fullAddress"
                    name="fullAddress"
                    label="Açık Adres"
                    multiline
                    rows={4}
                    value={values.fullAddress}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.fullAddress && Boolean(errors.fullAddress)}
                    helperText={touched.fullAddress && errors.fullAddress}
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.isDefault}
                        onChange={handleChange}
                        name="isDefault"
                      />
                    }
                    label="Varsayılan Adres"
                  />
                </Grid>
              </Grid>

              <Box
                sx={{
                  mt: 3,
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: 1,
                }}
              >
                <Button onClick={onCancel} variant="outlined" color="inherit">
                  İptal
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  disabled={isSubmitting}
                  color={mode === "add" ? "primary" : "success"}
                >
                  {mode === "add" ? "Kaydet" : "Düzenle"}
                </Button>
              </Box>
            </Paper>
          </Form>
        );
      }}
    </Formik>
  );
};

AddressForm.propTypes = {
  mode: PropTypes.oneOf(["add", "edit"]).isRequired,
  initialData: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string.isRequired,
    fullName: PropTypes.string.isRequired,
    phoneNumber: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    district: PropTypes.string.isRequired,
    neighborhood: PropTypes.string.isRequired,
    fullAddress: PropTypes.string.isRequired,
    isDefault: PropTypes.bool.isRequired,
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default withErrorBoundary(AddressForm, {
  resetButtonText: "Reload Address Form",
});
