import React, { useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import {
  FaUserCircle,
  FaSignOutAlt,
  FaCog,
  FaClipboardList,
} from "react-icons/fa";
import withErrorBoundary from "../hoc/withErrorBoundary";
import {
  Box,
  Paper,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
  Popper,
  Fade,
  ClickAwayListener,
} from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledListItem = styled(ListItem)(({ theme }) => ({
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
  },
}));

const LogoutButton = styled(Button)(({ theme }) => ({
  justifyContent: "flex-start",
  padding: theme.spacing(1, 2),
  width: "100%",
  color: theme.palette.error.main,
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
  },
}));

const ProfileDropdown = ({ isOpen, setIsOpen, anchorEl }) => {
  const { currentUser, logout } = useAuth();
  const navigate = useNavigate();

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleLogout = async () => {
    try {
      await logout();
      navigate("/");
      setIsOpen(false);
    } catch (error) {
      console.error("Logout failed:", error);
    }
  };

  return (
    <Popper
      open={isOpen}
      anchorEl={anchorEl}
      placement="bottom-end"
      transition
      style={{ zIndex: 1300 }}
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={200}>
          <Paper
            elevation={3}
            sx={{
              width: 280,
              mt: 1,
              overflow: "hidden",
            }}
          >
            <ClickAwayListener onClickAway={handleClose}>
              <Box>
                <Box sx={{ p: 2 }}>
                  <Typography variant="subtitle2">
                    {currentUser?.name || currentUser?.company_name}
                  </Typography>
                  <Typography variant="caption" color="text.secondary">
                    {currentUser?.email}
                  </Typography>
                </Box>

                <Divider />

                <List sx={{ py: 0 }}>
                  {currentUser?.userType === "seller" && (
                    <StyledListItem
                      button
                      onClick={() => {
                        navigate("/seller/dashboard");
                        handleClose();
                      }}
                    >
                      <ListItemIcon sx={{ minWidth: 36 }}>
                        <FaClipboardList />
                      </ListItemIcon>
                      <ListItemText primary="Satıcı Paneli" />
                    </StyledListItem>
                  )}

                  {currentUser?.userType === "buyer" && (
                    <StyledListItem
                      button
                      onClick={() => {
                        navigate("/buyer/dashboard");
                        handleClose();
                      }}
                    >
                      <ListItemIcon sx={{ minWidth: 36 }}>
                        <FaUserCircle />
                      </ListItemIcon>
                      <ListItemText primary="Profil" />
                    </StyledListItem>
                  )}

                  <StyledListItem
                    button
                    onClick={() => {
                      navigate("/settings");
                      handleClose();
                    }}
                  >
                    <ListItemIcon sx={{ minWidth: 36 }}>
                      <FaCog />
                    </ListItemIcon>
                    <ListItemText primary="Ayarlar" />
                  </StyledListItem>

                  <LogoutButton
                    onClick={handleLogout}
                    startIcon={<FaSignOutAlt />}
                  >
                    Çıkış Yap
                  </LogoutButton>
                </List>
              </Box>
            </ClickAwayListener>
          </Paper>
        </Fade>
      )}
    </Popper>
  );
};

export default withErrorBoundary(ProfileDropdown, {
  resetButtonText: "Reload Profile Menu",
});
