import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import withErrorBoundary from "../hoc/withErrorBoundary";
import { Box } from "@mui/material";

function Layout({ children, darkMode, toggleDarkMode }) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        bgcolor: "background.default",
        color: "text.primary",
      }}
    >
      <Header darkMode={darkMode} toggleDarkMode={toggleDarkMode} />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 4,
          width: "100%",
        }}
      >
        {children}
      </Box>
      <Footer darkMode={darkMode} />
    </Box>
  );
}

export default withErrorBoundary(Layout, {
  resetPath: "/",
  resetButtonText: "Return to Home",
});
