import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import {
  Box,
  Container,
  Typography,
  Button,
  IconButton,
  useTheme,
  alpha,
} from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const heroSlides = [
  {
    type: 'hero',
    title: 'El Sanatları ve Tasarım Dünyası',
    subtitle: 'Yetenekli ustaların eşsiz tasarımlarını keşfedin',
    cta: [
      { text: 'Keşfetmeye Başla', link: '/products', primary: true },
      { text: 'Ustalarla Tanış', link: '/sellers', primary: false }
    ],
    bgPattern: 'radial-gradient(circle at 50% 50%, rgba(255,255,255,0.05) 0%, rgba(255,255,255,0) 70%)'
  },
  {
    type: 'featured',
    title: 'Güvenli Ödeme Sistemi',
    content: 'Artık tüm ödemeleriniz güvence altında. Ürününüz elinize ulaşmadan ödemeniz satıcıya aktarılmaz.',
    badge: 'Güvenli Alışveriş',
    link: '/payment-security',
    bgPattern: 'linear-gradient(45deg, rgba(255,255,255,0.05) 25%, transparent 25%, transparent 75%, rgba(255,255,255,0.05) 75%)'
  },
  {
    type: 'news',
    title: 'Usta Değerlendirme Sistemi',
    content: 'Deneyimlerinizi paylaşın, en iyi ustaları keşfedin. Detaylı değerlendirme sistemi ile doğru ustayı bulun.',
    badge: 'Yeni',
    link: '/ratings',
    bgPattern: 'repeating-linear-gradient(45deg, rgba(255,255,255,0.03) 0px, rgba(255,255,255,0.03) 2px, transparent 2px, transparent 4px)'
  }
];

const HeroSection = () => {
  const theme = useTheme();
  const [currentSlide, setCurrentSlide] = useState(0);
  const [direction, setDirection] = useState(0);

  const slideVariants = {
    enter: (direction) => ({
      x: direction > 0 ? 1000 : -1000,
      opacity: 0,
      scale: 0.9,
    }),
    center: {
      x: 0,
      opacity: 1,
      scale: 1,
      transition: {
        duration: 0.5,
        type: "spring",
        stiffness: 300,
        damping: 30
      }
    },
    exit: (direction) => ({
      x: direction > 0 ? -1000 : 1000,
      opacity: 0,
      scale: 0.9,
      transition: {
        duration: 0.3
      }
    })
  };

  const backgroundVariants = {
    initial: {
      backgroundPosition: '0% 0%',
    },
    animate: {
      backgroundPosition: ['0% 0%', '100% 100%'],
      transition: {
        duration: 20,
        ease: "linear",
        repeat: Infinity,
      }
    }
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setDirection(1);
      setCurrentSlide((prev) => (prev + 1) % heroSlides.length);
    }, 50000);
    return () => clearInterval(timer);
  }, []);

  const nextSlide = () => {
    setDirection(1);
    setCurrentSlide((prev) => (prev + 1) % heroSlides.length);
  };

  const prevSlide = () => {
    setDirection(-1);
    setCurrentSlide((prev) => (prev - 1 + heroSlides.length) % heroSlides.length);
  };

  const renderSlideContent = (slide) => {
    return (
      <motion.div
        key={currentSlide}
        custom={direction}
        variants={slideVariants}
        initial="enter"
        animate="center"
        exit="exit"
        style={{ width: '100%', height: '100%', position: 'absolute' }}
      >
        <Container maxWidth="lg" sx={{ height: '100%', display: 'flex', alignItems: 'center' }}>
          <Box sx={{ 
            position: 'relative',
            zIndex: 2,
            textAlign: 'center',
            maxWidth: '800px',
            mx: 'auto',
            px: 4
          }}>
            {slide.badge && (
              <motion.div
                initial={{ y: -20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ delay: 0.2 }}
              >
                <Typography
                  variant="caption"
                  sx={{
                    display: 'inline-block',
                    px: 2,
                    py: 1,
                    borderRadius: '50px',
                    bgcolor: alpha(theme.palette.primary.main, 0.1),
                    color: 'primary.main',
                    mb: 2,
                    backdropFilter: 'blur(4px)',
                  }}
                >
                  {slide.badge}
                </Typography>
              </motion.div>
            )}

            <motion.div
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.3 }}
            >
              <Typography
                variant="h1"
                sx={{
                  mb: 3,
                  fontFamily: theme.typography.display,
                  background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                  backgroundClip: 'text',
                  textFillColor: 'transparent',
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                  fontSize: { xs: '2.5rem', md: '3.5rem' },
                  fontWeight: 'bold',
                }}
              >
                {slide.title}
              </Typography>
            </motion.div>

            <motion.div
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.4 }}
            >
              <Typography 
                variant="h5" 
                color="text.secondary" 
                sx={{ 
                  mb: 4,
                  fontSize: { xs: '1.2rem', md: '1.5rem' },
                  textShadow: `0 2px 4px ${alpha(theme.palette.background.default, 0.5)}`
                }}
              >
                {slide.subtitle || slide.content}
              </Typography>
            </motion.div>

            <motion.div
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.5 }}
            >
              {slide.cta ? (
                <Box sx={{ display: 'flex', gap: 2, justifyContent: 'center' }}>
                  {slide.cta.map((button, index) => (
                    <Button
                      key={index}
                      component={Link}
                      to={button.link}
                      variant={button.primary ? 'contained' : 'outlined'}
                      size="large"
                      sx={{
                        px: 4,
                        py: 1.5,
                        borderRadius: '50px',
                        transition: 'all 0.3s',
                        backdropFilter: 'blur(4px)',
                        '&:hover': {
                          transform: button.primary ? 'scale(1.05)' : 'none',
                          boxShadow: button.primary ? theme.shadows[8] : 'none',
                        },
                      }}
                    >
                      {button.text}
                    </Button>
                  ))}
                </Box>
              ) : (
                <Button
                  component={Link}
                  to={slide.link}
                  variant="contained"
                  size="large"
                  sx={{
                    px: 4,
                    py: 1.5,
                    borderRadius: '50px',
                    transition: 'all 0.3s',
                    backdropFilter: 'blur(4px)',
                    '&:hover': {
                      transform: 'scale(1.05)',
                      boxShadow: theme.shadows[8],
                    },
                  }}
                >
                  Daha Fazla Bilgi
                </Button>
              )}
            </motion.div>
          </Box>
        </Container>
      </motion.div>
    );
  };

  return (
    <Box
      sx={{
        position: 'relative',
        height: '80vh',
        minHeight: '600px',
        overflow: 'hidden',
        bgcolor: alpha(theme.palette.primary.main, 0.05),
        '&::before': {
          content: '""',
          position: 'absolute',
          inset: 0,
          background: heroSlides[currentSlide].bgPattern,
          opacity: 0.5,
          backgroundSize: '30px 30px',
        }
      }}
    >
      <motion.div
        variants={backgroundVariants}
        initial="initial"
        animate="animate"
        style={{
          position: 'absolute',
          inset: 0,
          background: `radial-gradient(circle at center, ${alpha(theme.palette.primary.main, 0.1)} 0%, transparent 70%)`,
        }}
      />

      <AnimatePresence initial={false} custom={direction} mode="wait">
        {renderSlideContent(heroSlides[currentSlide])}
      </AnimatePresence>

      <Box
        sx={{
          position: 'absolute',
          inset: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          px: { xs: 2, md: 4 },
          pointerEvents: 'none',
        }}
      >
        <IconButton
          onClick={prevSlide}
          sx={{
            bgcolor: alpha(theme.palette.background.paper, 0.1),
            backdropFilter: 'blur(4px)',
            color: 'text.primary',
            pointerEvents: 'auto',
            '&:hover': {
              bgcolor: alpha(theme.palette.background.paper, 0.2),
              transform: 'scale(1.1)',
            },
            transition: 'all 0.3s',
          }}
        >
          <ArrowBackIosNewIcon />
        </IconButton>
        <IconButton
          onClick={nextSlide}
          sx={{
            bgcolor: alpha(theme.palette.background.paper, 0.1),
            backdropFilter: 'blur(4px)',
            color: 'text.primary',
            pointerEvents: 'auto',
            '&:hover': {
              bgcolor: alpha(theme.palette.background.paper, 0.2),
              transform: 'scale(1.1)',
            },
            transition: 'all 0.3s',
          }}
        >
          <ArrowForwardIosIcon />
        </IconButton>
      </Box>

      {/* Slide indicators */}
      <Box
        sx={{
          position: 'absolute',
          bottom: 32,
          left: '50%',
          transform: 'translateX(-50%)',
          display: 'flex',
          gap: 1,
        }}
      >
        {heroSlides.map((_, index) => (
          <Box
            key={index}
            component={motion.div}
            animate={{
              scale: currentSlide === index ? 1.2 : 1,
              opacity: currentSlide === index ? 1 : 0.5,
            }}
            sx={{
              width: 8,
              height: 8,
              borderRadius: '50%',
              bgcolor: 'primary.main',
              cursor: 'pointer',
            }}
            onClick={() => {
              setDirection(index > currentSlide ? 1 : -1);
              setCurrentSlide(index);
            }}
          />
        ))}
      </Box>
    </Box>
  );
};

export default HeroSection; 