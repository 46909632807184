import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import withErrorBoundary from "../../hoc/withErrorBoundary";
import { TextField, Button, Box, Alert, Container } from "@mui/material";

const RegisterForm = ({ userType }) => {
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const { register } = useAuth();

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Geçerli bir e-posta adresi giriniz")
      .required("E-posta adresi zorunludur")
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Geçerli bir e-posta formatı giriniz"
      ),
    password: Yup.string()
      .required("Şifre zorunludur")
      .min(8, "Şifre en az 8 karakter olmalıdır")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]/,
        "Şifre en az bir büyük harf, bir küçük harf, bir rakam ve bir özel karakter içermelidir"
      ),
    confirmPassword: Yup.string()
      .required("Şifre tekrarı zorunludur")
      .oneOf([Yup.ref("password")], "Şifreler eşleşmiyor"),
    name:
      userType === "buyer"
        ? Yup.string()
            .required("Ad Soyad zorunludur")
            .min(2, "Ad Soyad en az 2 karakter olmalıdır")
            .matches(/^[a-zA-ZğüşıöçĞÜŞİÖÇ\s]+$/, "Sadece harf kullanılabilir")
        : Yup.string(),
    company_name:
      userType === "seller"
        ? Yup.string()
            .required("Şirket adı zorunludur")
            .min(2, "Şirket adı en az 2 karakter olmalıdır")
        : Yup.string(),
  });

  const initialValues = {
    email: "",
    password: "",
    confirmPassword: "",
    name: "",
    company_name: "",
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      setError("");
      await register(
        {
          email: values.email,
          password: values.password,
          name: userType === "buyer" ? values.name : undefined,
          company_name: userType === "seller" ? values.company_name : undefined,
        },
        userType
      );
      navigate(userType === "buyer" ? "/products" : "/seller/dashboard");
    } catch (err) {
      setError(err.message || "Kayıt olurken bir hata oluştu");
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Container maxWidth="sm">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          isSubmitting,
          touched,
          errors,
          handleChange,
          handleBlur,
          values,
        }) => (
          <Form>
            <Box sx={{ mt: 3 }}>
              {error && (
                <Alert severity="error" sx={{ mb: 2 }}>
                  {error}
                </Alert>
              )}

              <Box sx={{ mb: 2 }}>
                <TextField
                  fullWidth
                  id="email"
                  name="email"
                  label="E-posta"
                  type="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.email && Boolean(errors.email)}
                  helperText={touched.email && errors.email}
                />
              </Box>

              {userType === "buyer" && (
                <Box sx={{ mb: 2 }}>
                  <TextField
                    fullWidth
                    id="name"
                    name="name"
                    label="Ad Soyad"
                    type="text"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.name && Boolean(errors.name)}
                    helperText={touched.name && errors.name}
                  />
                </Box>
              )}

              {userType === "seller" && (
                <Box sx={{ mb: 2 }}>
                  <TextField
                    fullWidth
                    id="company_name"
                    name="company_name"
                    label="Şirket Adı"
                    type="text"
                    value={values.company_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.company_name && Boolean(errors.company_name)}
                    helperText={touched.company_name && errors.company_name}
                  />
                </Box>
              )}

              <Box sx={{ mb: 2 }}>
                <TextField
                  fullWidth
                  id="password"
                  name="password"
                  label="Şifre"
                  type="password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.password && Boolean(errors.password)}
                  helperText={touched.password && errors.password}
                />
              </Box>

              <Box sx={{ mb: 3 }}>
                <TextField
                  fullWidth
                  id="confirmPassword"
                  name="confirmPassword"
                  label="Şifre Tekrar"
                  type="password"
                  value={values.confirmPassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    touched.confirmPassword && Boolean(errors.confirmPassword)
                  }
                  helperText={touched.confirmPassword && errors.confirmPassword}
                />
              </Box>

              <Button
                type="submit"
                fullWidth
                variant="contained"
                disabled={isSubmitting}
                sx={{ mb: 2 }}
              >
                {isSubmitting
                  ? "Kayıt yapılıyor..."
                  : userType === "buyer"
                  ? "Alıcı Kaydı"
                  : "Satıcı Kaydı"}
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default withErrorBoundary(RegisterForm, {
  resetButtonText: "Reload Registration Form",
});
