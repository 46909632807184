import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { FaShoppingCart, FaMinus, FaPlus, FaCheck } from "react-icons/fa";
import { useCart } from "../../contexts/CartContext";
import withErrorBoundary from "../../hoc/withErrorBoundary";
import { IconButton, Button, Tooltip, Box } from "@mui/material";
import { styled } from "@mui/material/styles";

// Styled components
const CartButton = styled(Button)(({ theme, size }) => ({
  width: "100%",
  borderRadius: "9999px",
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  padding: size === "large" ? theme.spacing(2) : theme.spacing(1),
  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const QuantityContainer = styled(Box)(({ theme, size }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  gap: theme.spacing(1),
  borderRadius: "9999px",
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  padding: size === "large" ? theme.spacing(2) : theme.spacing(1),
}));

const AddToCartButton = ({ product, size = "small", className = "" }) => {
  const { cart, addToCart, updateQuantity, removeFromCart } = useCart();
  const [showSuccess, setShowSuccess] = useState(false);

  const cartItem = cart.find((item) => item.id === product.id);
  const quantity = cartItem?.quantity || 0;

  const handleAddToCart = (e) => {
    e.preventDefault();
    addToCart(product);
    setShowSuccess(true);
    setTimeout(() => setShowSuccess(false), 1500);
  };

  const handleIncrement = (e) => {
    e.preventDefault();
    addToCart(product);
  };

  const handleDecrement = (e) => {
    e.preventDefault();
    if (quantity === 1) {
      removeFromCart(product.id);
    } else {
      updateQuantity(product.id, quantity - 1);
    }
  };

  const iconSize = size === "large" ? 20 : 16;
  const containerWidth = size === "large" ? "8rem" : "6rem";

  return (
    <Box sx={{ position: "relative", width: containerWidth, className }}>
      <motion.div layout>
        <AnimatePresence mode="wait">
          {showSuccess ? (
            <motion.div
              key="success"
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.8 }}
              transition={{ duration: 0.2 }}
            >
              <CartButton size={size} fullWidth>
                <FaCheck size={iconSize} />
              </CartButton>
            </motion.div>
          ) : quantity > 0 ? (
            <motion.div
              key="cart-quantity"
              layout
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.8 }}
            >
              <QuantityContainer size={size}>
                <IconButton
                  onClick={handleDecrement}
                  size="small"
                  sx={{ color: "white" }}
                >
                  <FaMinus size={iconSize} />
                </IconButton>
                <span>{quantity}</span>
                <IconButton
                  onClick={handleIncrement}
                  size="small"
                  sx={{ color: "white" }}
                >
                  <FaPlus size={iconSize} />
                </IconButton>
              </QuantityContainer>
            </motion.div>
          ) : (
            <motion.div
              key="cart-button"
              layout
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.8 }}
            >
              <Tooltip title="Sepete Ekle" arrow>
                <CartButton
                  onClick={handleAddToCart}
                  size={size}
                  component={motion.button}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  <FaShoppingCart size={iconSize} />
                </CartButton>
              </Tooltip>
            </motion.div>
          )}
        </AnimatePresence>
      </motion.div>
    </Box>
  );
};

export default withErrorBoundary(AddToCartButton, {
  resetButtonText: "Retry",
});
