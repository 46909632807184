import React, { useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { FaUser, FaEnvelope, FaCalendar } from "react-icons/fa";
import Modal from "../Modal";
import ProfileForm from "../forms/ProfileForm";
import withErrorBoundary from "../../hoc/withErrorBoundary";
import {
  Box,
  Typography,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Container,
  Paper,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

const ProfilePanel = () => {
  const { currentUser, updateUser } = useAuth();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleSubmit = async (formData) => {
    try {
      await updateUser(formData);
      setIsModalOpen(false);
    } catch (error) {
      console.error("Profil güncellenirken hata:", error);
    }
  };

  const ProfileItem = ({ icon, label, value }) => (
    <ListItem>
      <ListItemIcon sx={{ minWidth: 40 }}>{icon}</ListItemIcon>
      <ListItemText
        primary={
          <Typography variant="body2" color="text.secondary">
            {label}
          </Typography>
        }
        secondary={
          <Typography variant="body1" sx={{ mt: 0.5 }}>
            {value}
          </Typography>
        }
      />
    </ListItem>
  );

  return (
    <Container>
      <Paper elevation={0} sx={{ p: 3 }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            mb: 3,
          }}
        >
          <Typography variant="h5" component="h2" fontWeight="600">
            Profil Bilgileri
          </Typography>
          <Button
            variant="contained"
            startIcon={<EditIcon />}
            onClick={() => setIsModalOpen(true)}
          >
            Düzenle
          </Button>
        </Box>

        <List sx={{ "& .MuiListItem-root": { py: 2 } }}>
          <ProfileItem
            icon={<FaUser />}
            label="Ad Soyad"
            value={currentUser?.name}
          />
          <ProfileItem
            icon={<FaEnvelope />}
            label="E-posta"
            value={currentUser?.email}
          />
          <ProfileItem
            icon={<FaCalendar />}
            label="Kayıt Tarihi"
            value={new Date(currentUser?.createdAt).toLocaleDateString("tr-TR")}
          />
          <ProfileItem
            icon={<FaCalendar />}
            label="Son Güncelleme"
            value={new Date(currentUser?.updatedAt).toLocaleDateString("tr-TR")}
          />
        </List>

        <Modal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          title="Profil Bilgilerini Düzenle"
        >
          <ProfileForm
            currentUser={currentUser}
            onSubmit={handleSubmit}
            onCancel={() => setIsModalOpen(false)}
          />
        </Modal>
      </Paper>
    </Container>
  );
};

export default withErrorBoundary(ProfilePanel, {
  resetButtonText: "Reload Profile",
});
