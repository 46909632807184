import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { StarIcon, ShoppingBagIcon } from "@heroicons/react/solid";
import { statsApi, productsApi, sellersApi } from "../../utils/api";
import withErrorBoundary from "../../hoc/withErrorBoundary";
import {
  Card,
  CardContent,
  Typography,
  Box,
  Chip,
  Avatar,
} from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledCard = styled(Card)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius * 2,
  overflow: "hidden",
  transition: "box-shadow 0.3s ease-in-out",
  "&:hover": {
    boxShadow: theme.shadows[8],
  },
}));

const CoverImage = styled(Box)(({ theme }) => ({
  height: theme.spacing(16),
  background: (theme) => theme.palette.primary.main,
  backgroundSize: "cover",
  backgroundPosition: "center",
}));

const ProfileImageWrapper = styled(Box)(({ theme }) => ({
  position: "absolute",
  left: "50%",
  transform: "translate(-50%, -50%)",
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(12),
  height: theme.spacing(12),
  border: `4px solid ${theme.palette.background.paper}`,
}));

const StatChip = styled(Chip)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  "& .MuiChip-icon": {
    color: "inherit",
  },
}));

const SpecialtyChip = styled(Chip)(({ theme }) => ({
  backgroundColor: theme.palette.background.light,
  color: theme.palette.primary.light,
  fontSize: theme.typography.pxToRem(12),
  fontWeight: 500,
  "&:hover": {
    backgroundColor: theme.palette.secondary.light,
  },
  "& .MuiChip-label": {
    padding: "0 8px",
  },
}));

const SellerCard = ({ seller }) => {
  const [averageRating, setAverageRating] = useState(0);
  const [totalReviews, setTotalReviews] = useState(0);
  const [numberOfProducts, setNumberOfProducts] = useState(0);

  useEffect(() => {
    const fetchSellerData = async () => {
      try {
        console.log(seller);
        const [productsRes, statsRes] = await Promise.all([
          productsApi.getSellerProducts(seller.id),
          statsApi.getSellerAverageRating(seller.id),
        ]);
        setAverageRating(statsRes.data?.averageRating || 0);
        setTotalReviews(statsRes.data?.totalReviews || 0);
        setNumberOfProducts(productsRes.data.length);
      } catch (error) {
        console.error("Error fetching seller data:", error);
      }
    };

    fetchSellerData();
  }, [seller.id]);

  return (
    <Link to={`/seller/${seller.id}`} style={{ textDecoration: "none" }}>
      <StyledCard>
        <Box position="relative">
          <CoverImage
            sx={{
              background: (theme) =>
                seller.useDefaultCover
                  ? `linear-gradient(to right, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`
                  : "none",
            }}
          >
            {!seller.useDefaultCover && seller.coverImage && (
              <Box
                component="img"
                src={sellersApi.getSellerCoverImageUrl(seller.id)}
                alt="cover"
                sx={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            )}
          </CoverImage>
          <ProfileImageWrapper>
            <StyledAvatar
              src={sellersApi.getSellerProfileImageUrl(seller.id)}
              alt={seller.name}
            />
          </ProfileImageWrapper>
        </Box>

        <CardContent sx={{ pt: 8 }}>
          <Typography variant="h6" align="center" gutterBottom>
            {seller.name}
          </Typography>

          <Typography
            variant="body2"
            color="text.secondary"
            align="center"
            gutterBottom
          >
            {seller.city}
          </Typography>

          <Box display="flex" justifyContent="center" gap={3} mb={2}>
            <StatChip
              icon={<StarIcon className="h-5 w-5" />}
              label={`${averageRating} (${totalReviews})`}
              variant="outlined"
            />
            <StatChip
              icon={<ShoppingBagIcon className="h-5 w-5" />}
              label={`${numberOfProducts} Ürün`}
              variant="outlined"
              sx={{ '& .MuiChip-label': { paddingRight: 1 } }}
            />
          </Box>

          <Box display="flex" flexWrap="wrap" justifyContent="center" gap={1}>
            {seller.customTags?.slice(0, 3).map((tag, index) => (
              <SpecialtyChip
                key={index}
                label={tag}
                size="small"
                variant="outlined"
              />
            ))}
          </Box>
        </CardContent>
      </StyledCard>
    </Link>
  );
};

export default withErrorBoundary(SellerCard, {
  resetButtonText: "Retry Loading Seller",
});
