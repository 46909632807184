import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { Box, Typography, Container, useTheme } from "@mui/material";
import RegisterForm from "../components/forms/RegisterForm";
import ProductShowcase from "../components/ProductShowcase";
import withErrorBoundary from "../hoc/withErrorBoundary";

const RegisterPage = () => {
  const theme = useTheme();

  return (
    <Box sx={{ minHeight: "100vh", display: "flex" }}>
      <ProductShowcase />

      <motion.div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: theme.spacing(8),
          paddingTop: theme.spacing(16),
        }}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Container maxWidth="sm">
          <motion.div
            initial={{ scale: 0.9 }}
            animate={{ scale: 1 }}
            transition={{ duration: 0.5 }}
          >
            <Typography
              variant="h3"
              component="h1"
              sx={{
                mb: 8,
                textAlign: "center",
                color: "primary.main",
                fontWeight: "bold",
              }}
            >
              Alıcı Kaydı
            </Typography>
          </motion.div>

          <motion.div
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.2 }}
          >
            <RegisterForm userType="buyer" />
          </motion.div>

          <motion.div
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.3 }}
          >
            <Typography
              variant="body1"
              sx={{
                mt: 6,
                textAlign: "center",
                color: "text.secondary",
              }}
            >
              Zaten hesabınız var mı?{" "}
              <Link
                to="/login"
                style={{
                  color: theme.palette.primary.main,
                  fontWeight: 600,
                  textDecoration: "none",
                  "&:hover": {
                    textDecoration: "underline",
                  },
                }}
              >
                Giriş yapın!
              </Link>
            </Typography>
          </motion.div>
        </Container>
      </motion.div>
    </Box>
  );
};

export default withErrorBoundary(RegisterPage, {
  resetButtonText: "Reload Registration Page",
});
