import React, { useState, useEffect } from "react";
import { Box, Container, Typography, Grid, useTheme } from "@mui/material";
import CategoryCard from "../components/cards/CategoryCard";
import ProductCard from "../components/cards/ProductCard";
import SellerCard from "../components/cards/SellerCard";
import HeroSection from "../components/hero/HeroSection";
import { statsApi } from "../utils/api";
import withErrorBoundary from "../hoc/withErrorBoundary";
import { motion } from "framer-motion";
import { CircularProgress } from "@mui/material";

function HomePage() {
  const theme = useTheme();
  const [featuredCategories, setFeaturedCategories] = useState([]);
  const [featuredProducts, setFeaturedProducts] = useState([]);
  const [featuredSellers, setFeaturedSellers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [categoriesRes, productsRes, sellersRes] = await Promise.all([
          statsApi.getMostBoughtCategories(1),
          statsApi.getMostBoughtProducts(12),
          statsApi.getMostSellingSellers(12),
        ]);
        setFeaturedCategories(categoriesRes.data);
        setFeaturedProducts(productsRes.data);
        setFeaturedSellers(sellersRes.data);
      } catch (error) {
        console.error("Error fetching homepage data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return (
      <Box
        sx={{
          minHeight: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <motion.div
          animate={{
            scale: [1, 1.2, 1],
            opacity: [0.5, 1, 0.5],
          }}
          transition={{
            duration: 1.5,
            repeat: Infinity,
            ease: "easeInOut",
          }}
        >
          <CircularProgress color="primary" />
        </motion.div>
      </Box>
    );
  }

  return (
    <Box sx={{  animation: "fadeIn 0.5s ease-in" }}>
      <HeroSection />

      {/* Rest of the content */}
      <Container maxWidth="lg" sx={{ maxWidth: "90% !important" }}>
        {/* Featured Categories */}
        <Box sx={{ mb: 8 }}>
          <Typography variant="h2" sx={{ mb: 4 }}>
            Popüler Kategoriler
          </Typography>
          <Grid container spacing={3}>
            {featuredCategories.map((category) => (
              <Grid item xs={12} sm={6} md={4} key={category.category.id}>
                <CategoryCard category={category.category} />
              </Grid>
            ))}
          </Grid>
        </Box>

        {/* Featured Products */}
        <Box sx={{ mb: 8 }}>
          <Typography variant="h2" sx={{ mb: 4 }}>
            Öne Çıkan Ürünler
          </Typography>
          <Grid container spacing={3}>
            {featuredProducts.map((product) => (
              <Grid item xs={12} sm={6} md={3} key={product.product.id}>
                <ProductCard product={product.product} />
              </Grid>
            ))}
          </Grid>
        </Box>

        {/* Featured Sellers */}
        <Box>
          <Typography variant="h2" sx={{ mb: 4 }}>
            Başarılı Satıcılar
          </Typography>
          <Grid container spacing={3}>
            {featuredSellers.map((seller) => (
              <Grid item xs={12} sm={6} md={3} key={seller.seller.id}>
                <SellerCard seller={seller.seller} />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
    </Box>
  );
}

export default withErrorBoundary(HomePage, {
  resetButtonText: "Reload Home Page",
});
