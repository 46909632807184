import React, { useState } from "react";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/outline";
import withErrorBoundary from "../../hoc/withErrorBoundary";
import {
  Card,
  CardContent,
  Typography,
  Box,
  Grid,
  Button,
  Chip,
  Collapse,
  IconButton,
  Divider,
  Paper,
} from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledCard = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius * 2,
  marginBottom: theme.spacing(2),
  boxShadow: theme.shadows[2],
  overflow: "hidden",
  "&:hover": {
    boxShadow: theme.shadows[4],
  },
}));

const OrderItemCard = styled(Paper)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(2),
  padding: theme.spacing(1),
  backgroundColor: theme.palette.grey[50],
  borderRadius: theme.shape.borderRadius,
}));

const ActionButton = styled(Button)(({ theme, variant = "contained" }) => ({
  borderRadius: theme.shape.borderRadius,
  textTransform: "none",
  ...(variant === "outlined" && {
    borderColor: theme.palette.grey[300],
    "&:hover": {
      backgroundColor: theme.palette.grey[50],
      borderColor: theme.palette.grey[400],
    },
  }),
}));

const STATUS_STYLES = {
  completed: { color: "success" },
  pending: { color: "warning" },
  processing: { color: "info" },
  cancelled: { color: "error" },
  default: { color: "default" },
};

const STATUS_TEXTS = {
  completed: "Tamamlandı",
  pending: "Beklemede",
  processing: "İşlemde",
  cancelled: "İptal Edildi",
};

const OrderCard = ({ order }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const getStatusStyle = (status) =>
    STATUS_STYLES[status] || STATUS_STYLES.default;
  const getStatusText = (status) => STATUS_TEXTS[status] || status;

  return (
    <StyledCard>
      <CardContent
        sx={{
          cursor: "pointer",
          "&:hover": { bgcolor: "action.hover" },
          p: 2,
        }}
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <Grid container spacing={2} alignItems="center">
          <Grid item xs>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={2.4}>
                <Typography variant="body2" color="text.secondary">
                  Sipariş No
                </Typography>
                <Typography variant="body1" fontWeight="medium">
                  {order.id}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={2.4}>
                <Typography variant="body2" color="text.secondary">
                  Satıcı
                </Typography>
                <Typography variant="body1" fontWeight="medium">
                  {order.seller?.name}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={2.4}>
                <Typography variant="body2" color="text.secondary">
                  Tarih
                </Typography>
                <Typography variant="body1" fontWeight="medium">
                  {new Date(order.createdAt).toLocaleDateString("tr-TR")}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={2.4}>
                <Typography variant="body2" color="text.secondary">
                  Toplam
                </Typography>
                <Typography variant="body1" fontWeight="medium">
                  {order.total} ₺
                </Typography>
              </Grid>
              <Grid item xs={12} sm={2.4}>
                <Typography variant="body2" color="text.secondary">
                  Durum
                </Typography>
                <Chip
                  size="small"
                  label={getStatusText(order.status)}
                  {...getStatusStyle(order.status)}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <IconButton size="small">
              {isExpanded ? (
                <ChevronUpIcon className="h-5 w-5" />
              ) : (
                <ChevronDownIcon className="h-5 w-5" />
              )}
            </IconButton>
          </Grid>
        </Grid>
      </CardContent>

      <Collapse in={isExpanded}>
        <Divider />
        <CardContent>
          <Box mb={3}>
            <Typography variant="h6" gutterBottom>
              Sipariş Detayları
            </Typography>
            <Box display="flex" flexDirection="column" gap={1}>
              {order.items?.map((item) => (
                <OrderItemCard key={item.id}>
                  <Box
                    component="img"
                    src={item.product?.image || "/placeholder.png"}
                    alt={item.product?.name}
                    sx={{
                      width: 64,
                      height: 64,
                      borderRadius: 1,
                      objectFit: "cover",
                    }}
                  />
                  <Box flex={1}>
                    <Typography variant="subtitle1" fontWeight="medium">
                      {item.product?.name}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Adet: {item.quantity} × {item.price} ₺
                    </Typography>
                  </Box>
                  <Typography variant="subtitle1" fontWeight="medium">
                    {item.quantity * item.price} ₺
                  </Typography>
                </OrderItemCard>
              ))}
            </Box>
          </Box>

          <Box display="flex" gap={1} mb={3}>
            <ActionButton variant="contained" color="primary">
              Fatura Detayları
            </ActionButton>
            {order.status === "completed" && (
              <ActionButton variant="contained" color="warning">
                İade Talebi
              </ActionButton>
            )}
            <ActionButton variant="outlined">Kargo Takibi</ActionButton>
            {order.status === "completed" && !order.reviewed && (
              <ActionButton variant="contained" color="success">
                Değerlendir
              </ActionButton>
            )}
          </Box>

          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Typography variant="h6" gutterBottom>
                Teslimat Adresi
              </Typography>
              <Paper sx={{ p: 2, bgcolor: "grey.50" }}>
                <Typography variant="body2" color="text.secondary">
                  {order.shippingAddress}
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h6" gutterBottom>
                Fatura Adresi
              </Typography>
              <Paper sx={{ p: 2, bgcolor: "grey.50" }}>
                <Typography variant="body2" color="text.secondary">
                  {order.billingAddress}
                </Typography>
              </Paper>
            </Grid>
          </Grid>
        </CardContent>
      </Collapse>
    </StyledCard>
  );
};

export default withErrorBoundary(OrderCard, {
  resetButtonText: "Reload Order",
});
