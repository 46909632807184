import React from "react";
import {
  Card,
  CardContent,
  Typography,
  IconButton,
  Box,
  Stack,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { FaEdit, FaTrash } from "react-icons/fa";
import withErrorBoundary from "../../hoc/withErrorBoundary";

const StyledCard = styled(Card)(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius * 2,
  transition: "transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out",
  "&:hover": {
    transform: "translateY(-4px)",
    boxShadow: theme.shadows[4],
  },
}));

const ActionButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.text.secondary,
  "&:hover": {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.action.hover,
  },
}));

const AddressCard = ({
  address,
  onEdit,
  onDelete,
  isSelected,
  onClick,
  className = "",
}) => {
  const {
    title,
    fullName,
    phoneNumber,
    city,
    district,
    neighborhood,
    fullAddress,
  } = address;

  return (
    <StyledCard
      onClick={onClick}
      className={className}
      sx={{
        border: (theme) =>
          isSelected ? `2px solid ${theme.palette.primary.main}` : "none",
        cursor: onClick ? "pointer" : "default",
      }}
    >
      <CardContent>
        <Stack spacing={1}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h6" component="h3" color="primary">
              {title}
            </Typography>
            <Box>
              {onEdit && (
                <ActionButton
                  onClick={(e) => {
                    e.stopPropagation();
                    onEdit(address);
                  }}
                  size="small"
                  aria-label="edit address"
                >
                  <FaEdit />
                </ActionButton>
              )}
              {onDelete && (
                <ActionButton
                  onClick={(e) => {
                    e.stopPropagation();
                    onDelete(address.id);
                  }}
                  size="small"
                  aria-label="delete address"
                >
                  <FaTrash />
                </ActionButton>
              )}
            </Box>
          </Box>

          <Typography variant="body1" color="text.primary">
            {fullName}
          </Typography>

          <Typography variant="body2" color="text.secondary">
            {phoneNumber}
          </Typography>

          <Typography variant="body2" color="text.secondary">
            {`${neighborhood}, ${district}, ${city}`}
          </Typography>

          <Typography variant="body2" color="text.secondary">
            {fullAddress}
          </Typography>
        </Stack>
      </CardContent>
    </StyledCard>
  );
};

export default withErrorBoundary(AddressCard, {
  resetButtonText: "Retry",
});
