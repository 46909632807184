import React, { useState, useEffect } from "react";
import {
  Box,
  Container,
  Typography,
  Grid,
  CircularProgress,
  useTheme,
} from "@mui/material";
import { sellersApi } from "../utils/api";
import SellerCard from "../components/cards/SellerCard";
import withErrorBoundary from "../hoc/withErrorBoundary";

const SellersPage = () => {
  const theme = useTheme();
  const [sellers, setSellers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchSellers = async () => {
      const response = await sellersApi.getSellers();
      setSellers(response.data);
      setLoading(false);
    };

    fetchSellers();
  }, []);

  if (loading) {
    return (
      <Container
        maxWidth="lg"
        sx={{ py: 8, display: "flex", justifyContent: "center" }}
      >
        <CircularProgress />
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ py: 8 }}>
      <Typography
        variant="h2"
        component="h1"
        sx={{
          mb: 6,
          fontWeight: "bold",
        }}
      >
        Ustalar
      </Typography>
      <Grid container spacing={4}>
        {sellers.map((seller) => (
          <Grid item xs={12} md={6} lg={4} key={seller.id}>
            <SellerCard seller={seller} />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default withErrorBoundary(SellersPage, {
  resetButtonText: "Reload Sellers Page",
});
