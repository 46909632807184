import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Container,
  Typography,
  Grid,
  Paper,
  Rating,
  TextField,
  Button,
  List,
  ListItem,
  ListItemText,
  Divider,
  CircularProgress,
  useTheme,
} from "@mui/material";
import {
  productsApi,
  reviewsApi,
  ordersApi,
  categoriesApi,
} from "../utils/api";
import AddToCartButton from "../components/buttons/AddToCartButton";
import AddToWishlistButton from "../components/buttons/AddToWishlistButton";
import { useAuth } from "../contexts/AuthContext";
import ReviewCard from "../components/cards/ReviewCard";
import Breadcrumb from "../components/Breadcrumb";
import withErrorBoundary from "../hoc/withErrorBoundary";

const ProductDetailPage = () => {
  const { id } = useParams();
  const theme = useTheme();
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [reviewable, setReviewable] = useState(false);
  const [reviewabilityComment, setReviewabilityComment] = useState("");
  const [reviews, setReviews] = useState([]);
  const [userReview, setUserReview] = useState("");
  const [userRating, setUserRating] = useState(5);
  const [selectedImage, setSelectedImage] = useState(0);
  const [productImages, setProductImages] = useState([]);
  const [categoryInfo, setCategoryInfo] = useState({
    category: null,
    subcategory: null,
  });
  const { currentUser } = useAuth();

  useEffect(() => {
    fetchProduct();
    fetchReviews();
  }, [currentUser, id]);

  useEffect(() => {
    determineReviewability();
  }, [currentUser, id, reviews]);

  const fetchProduct = async () => {
    const product = await productsApi.getProductById(id);
    setProduct(product.data);
    // Find category and subcategory info
    const category = await categoriesApi.getCategoryById(
      product.data.categoryId
    );

    setCategoryInfo({
      ancestors: category.data.ancestors,
      category: category.data,
    });
    // Fetch all product images URLs
    const imageUrls = Array.from(
      { length: product.data.productImages.length },
      (_, index) => {
        return index === 0
          ? productsApi.getCoverImageUrl(id)
          : productsApi.getProductImageUrl(id, index);
      }
    );
    setProductImages(imageUrls);
    setLoading(false);
  };

  const fetchReviews = async () => {
    try {
      const reviews = await reviewsApi.getProductReviews(id);
      console.log(reviews);
      setReviews(reviews.data);
    } catch (error) {
      console.error(error);
    }
  };

  const determineReviewability = async () => {
    try {
      if (!currentUser) {
        setReviewabilityComment("Lütfen giriş yapınız.");
        setReviewable(false);
        return;
      }

      const orders = await ordersApi.getBuyerOrders();

      // Check if the product is in any of the orders
      const isInOrder = orders.data.some((order) =>
        order.products.some((product) => product.id === parseInt(id))
      );

      const previouslyReviewed = reviews.some(
        (review) => review.buyerId === currentUser.id
      );

      if (isInOrder) {
        if (previouslyReviewed) {
          setReviewabilityComment(
            "Bu ürün hakkında zaten bir yorum yapmıştınız."
          );
          setReviewable(false);
        } else {
          setReviewabilityComment("Bu ürün hakkında yorum yapabilirsiniz.");
          setReviewable(true);
        }
      } else {
        setReviewabilityComment(
          "Bu ürünü almadığınız için yorum yapamazsınız."
        );
        setReviewable(false);
      }
    } catch (error) {
      console.error("Error in determineReviewability:", error);
    }
  };

  const handleReviewSubmit = async (e) => {
    e.preventDefault();
    const newReview = {
      rating: userRating,
      comment: userReview,
      productId: id,
    };
    const response = await reviewsApi.createReview(id, newReview);
    console.log(response);

    // Reset form
    setUserReview("");
    setUserRating(5);

    // Refresh reviews and reviewability status
    await fetchReviews();
    await determineReviewability();
  };

  const calculateAverageRating = () => {
    if (reviews.length === 0) return 0;
    const sum = reviews.reduce((acc, review) => acc + review.rating, 0);
    return (sum / reviews.length).toFixed(1);
  };

  const getBreadcrumbItems = () => {
    const items = [{ path: "/categories", label: "Kategoriler" }];
    categoryInfo.ancestors.forEach((ancestor) => {
      items.push({
        path: `/category/${ancestor.id}`,
        label: ancestor.name,
      });
    });

    if (categoryInfo.category) {
      items.push({
        path: `/category/${categoryInfo.category.id}`,
        label: categoryInfo.category.name,
      });
    }

    items.push({
      path: `/product/${product.id}`,
      label: product.name,
    });

    return items;
  };

  if (loading) {
    return (
      <Container
        maxWidth="lg"
        sx={{ py: 8, display: "flex", justifyContent: "center" }}
      >
        <CircularProgress />
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ py: 8 }}>
      <Box sx={{ mb: 4 }}>
        <Breadcrumb items={getBreadcrumbItems()} />
      </Box>

      <Grid container spacing={4}>
        {/* Product Images */}
        <Grid item xs={12} md={6}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            {/* Main Image */}
            <Paper
              elevation={2}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                p: 2,
                borderRadius: 2,
                overflow: "hidden",
              }}
            >
              <Box
                component="img"
                src={productImages[selectedImage]}
                alt={`${product?.name} - Image ${selectedImage + 1}`}
                sx={{
                  width: "100%",
                  maxWidth: "md",
                  height: "auto",
                  objectFit: "cover",
                  borderRadius: 1,
                }}
              />
            </Paper>

            {/* Thumbnail Strip */}
            <Box
              sx={{
                display: "flex",
                gap: 1,
                overflowX: "auto",
                py: 1,
                "&::-webkit-scrollbar": {
                  height: 8,
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "background.paper",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "primary.main",
                  borderRadius: 4,
                },
              }}
            >
              {productImages.map((imageUrl, index) => (
                <Paper
                  key={index}
                  elevation={selectedImage === index ? 4 : 1}
                  sx={{
                    cursor: "pointer",
                    border: selectedImage === index ? 2 : 1,
                    borderColor:
                      selectedImage === index ? "primary.main" : "grey.300",
                    borderRadius: 1,
                    overflow: "hidden",
                    transition: "all 0.2s",
                    "&:hover": {
                      transform: "scale(1.05)",
                    },
                  }}
                  onClick={() => setSelectedImage(index)}
                >
                  <Box
                    component="img"
                    src={imageUrl}
                    alt={`${product?.name} - Thumbnail ${index + 1}`}
                    sx={{
                      width: 80,
                      height: 80,
                      objectFit: "cover",
                    }}
                  />
                </Paper>
              ))}
            </Box>
          </Box>
        </Grid>

        {/* Product Details */}
        <Grid item xs={12} md={6}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
            <Typography variant="h3" component="h1" sx={{ fontWeight: "bold" }}>
              {product?.name}
            </Typography>

            <Typography variant="h5" color="text.secondary">
              {product?.sellerName}
            </Typography>

            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              <Typography
                variant="h4"
                color="primary"
                sx={{ fontWeight: "bold" }}
              >
                {product.price.toFixed(2)} TL
              </Typography>
              <Box sx={{ display: "flex", gap: 1 }}>
                <AddToCartButton product={product} size="large" />
                <AddToWishlistButton product={product} size="large" />
              </Box>
            </Box>

            <Typography variant="body1" sx={{ my: 2 }}>
              {product?.description}
            </Typography>

            <Box>
              <Typography variant="h6" sx={{ mb: 1, fontWeight: "bold" }}>
                Malzemeler
              </Typography>
              <List>
                {product?.materials?.map((material, index) => (
                  <ListItem key={index}>
                    <ListItemText primary={material} />
                  </ListItem>
                ))}
              </List>
            </Box>

            <Typography variant="body1">
              <Box component="span" sx={{ fontWeight: "bold" }}>
                Boyutlar:
              </Box>{" "}
              {product.dimensions}
            </Typography>
          </Box>
        </Grid>
      </Grid>

      {/* Reviews Section */}
      <Box sx={{ mt: 8 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 4,
          }}
        >
          <Typography variant="h4" sx={{ fontWeight: "bold" }}>
            Değerlendirmeler ve Yorumlar
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center", gap: 4 }}>
            <Box sx={{ textAlign: "center" }}>
              <Typography variant="h3" sx={{ fontWeight: "bold" }}>
                {calculateAverageRating()}
              </Typography>
              <Typography variant="caption" color="text.secondary">
                / 5
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Rating value={Math.round(calculateAverageRating())} readOnly />
              <Typography variant="caption" color="text.secondary">
                {reviews.length} değerlendirme
              </Typography>
            </Box>
          </Box>
        </Box>

        {reviewable && (
          <Paper sx={{ p: 4, mb: 4, bgcolor: "background.paper" }}>
            <form onSubmit={handleReviewSubmit}>
              <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
                <Box>
                  <Typography variant="subtitle1" sx={{ mb: 1 }}>
                    Puanınız
                  </Typography>
                  <Rating
                    value={userRating}
                    onChange={(_, newValue) => setUserRating(newValue)}
                    size="large"
                  />
                </Box>
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  variant="outlined"
                  label="Yorumunuz"
                  value={userReview}
                  onChange={(e) => setUserReview(e.target.value)}
                />
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="large"
                  sx={{ alignSelf: "flex-start" }}
                >
                  Yorum Yap
                </Button>
              </Box>
            </form>
          </Paper>
        )}

        <Typography
          variant="body2"
          color={reviewable ? "success.main" : "error.main"}
          sx={{ mb: 4 }}
        >
          {reviewabilityComment}
        </Typography>

        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          {reviews.map((review) => (
            <ReviewCard
              key={review.id}
              review={review}
              isBuyerNameVisible={true}
              isProductNameVisible={false}
              isEditClickable={false}
              isEditVisible={false}
              onEditClick={() => {}}
              isProductClickable={false}
              isProductVisible={false}
            />
          ))}
        </Box>
      </Box>
    </Container>
  );
};

export default withErrorBoundary(ProductDetailPage, {
  resetButtonText: "Reload Product Page",
});
