import React, { useState, useEffect } from "react";
import OrdersPanel from "../components/panels/OrdersPanel";
import WishlistPanel from "../components/panels/WishlistPanel";
import ReviewsPanel from "../components/panels/ReviewsPanel";
import StatCard from "../components/cards/StatCard";
import AddressesPanel from "../components/panels/AddressesPanel";
import ProfilePanel from "../components/panels/ProfilePanel";
import { ordersApi, wishlistApi, reviewsApi } from "../utils/api";
import withErrorBoundary from "../hoc/withErrorBoundary";
import { Container, Grid, Paper, Tabs, Tab, Box } from "@mui/material";
import {
  Person as PersonIcon,
  ShoppingBag as ShoppingBagIcon,
  Favorite as FavoriteIcon,
  Star as StarIcon,
  LocationOn as LocationIcon,
} from "@mui/icons-material";

const BuyerDashboard = () => {
  const [activeTab, setActiveTab] = useState("orders");
  const [numOrders, setNumOrders] = useState(0);
  const [numWishlist, setNumWishlist] = useState(0);
  const [numReviews, setNumReviews] = useState(0);

  useEffect(() => {
    ordersApi.getBuyerOrders().then((response) => {
      setNumOrders(response.data.length);
    });
    wishlistApi.getWishlist().then((response) => {
      setNumWishlist(response.data.length);
    });
    reviewsApi.getBuyerReviews().then((response) => {
      setNumReviews(response.data.length);
    });
  }, []);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const renderPanel = () => {
    switch (activeTab) {
      case "profile":
        return <ProfilePanel />;
      case "orders":
        return <OrdersPanel />;
      case "wishlist":
        return <WishlistPanel />;
      case "reviews":
        return <ReviewsPanel />;
      case "addresses":
        return <AddressesPanel />;
      default:
        return <OrdersPanel />;
    }
  };

  return (
    <Container
      sx={{
        maxWidth: "90% !important",
      }}
    >
      <Grid container spacing={3} sx={{ mb: 4 }}>
        <Grid item xs={12} md={4}>
          <StatCard
            title="Toplam Siparişler"
            value={numOrders}
            icon={<ShoppingBagIcon />}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <StatCard
            title="İstek Listesi"
            value={numWishlist}
            icon={<FavoriteIcon />}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <StatCard
            title="Değerlendirmeler"
            value={numReviews}
            icon={<StarIcon />}
          />
        </Grid>
      </Grid>

      <Paper elevation={2}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            variant="fullWidth"
            scrollButtons="auto"
            aria-label="dashboard tabs"
            sx={{ 
              '& .MuiTab-root': {
                textTransform: 'none'
              }
            }}
          >
            <Tab
              icon={<PersonIcon />}
              iconPosition="start"
              label="Profil"
              value="profile"
            />
            <Tab
              icon={<ShoppingBagIcon />}
              iconPosition="start"
              label="Siparişler"
              value="orders"
            />
            <Tab
              icon={<FavoriteIcon />}
              iconPosition="start"
              label="İstek Listesi"
              value="wishlist"
            />
            <Tab
              icon={<StarIcon />}
              iconPosition="start"
              label="Değerlendirmeler"
              value="reviews"
            />
            <Tab
              icon={<LocationIcon />}
              iconPosition="start"
              label="Adreslerim"
              value="addresses"
            />
          </Tabs>
        </Box>
        <Box sx={{ p: 3 }}>{renderPanel()}</Box>
      </Paper>
    </Container>
  );
};

export default withErrorBoundary(BuyerDashboard, {
  resetButtonText: "Reload Dashboard",
});
