const CITY_DATA = {
	Adana: {
		code: 1,
		districts: [
			"Aladağ",
			"Ceyhan",
			"Çukurova",
			"Feke",
			"İmamoğlu",
			"Karaisalı",
			"Karataş",
			"Kozan",
			"Pozantı",
			"Saimbeyli",
			"Sarıçam",
			"Seyhan",
			"Tufanbeyli",
			"Yumurtalık",
			"Yüreğir"
		]
	},
	Adıyaman: {
		code: 2,
		districts: [
			"Besni",
			"Çelikhan",
			"Gerger",
			"Gölbaşı",
			"Kahta",
			"Merkez",
			"Samsat",
			"Sincik",
			"Tut"
		]
	},
	Afyonkarahisar: {
		code: 3,
		districts: [
			"Başmakçı",
			"Bayat",
			"Bolvadin",
			"Çay",
			"Çobanlar",
			"Dazkırı",
			"Dinar",
			"Emirdağ",
			"Evciler",
			"Hocalar",
			"İhsaniye",
			"İscehisar",
			"Kızılören",
			"Merkez",
			"Sandıklı",
			"Sinanpaşa",
			"Sultandağı",
			"Şuhut"
		]
	},
	Ağrı: {
		code: 4,
		districts: [
			"Diyadin",
			"Doğubayazıt",
			"Eleşkirt",
			"Hamur",
			"Merkez",
			"Patnos",
			"Taşlıçay",
			"Tutak"
		]
	},
	Amasya: {
		code: 5,
		districts: [
			"Göynücek",
			"Gümüşhacıköy",
			"Hamamözü",
			"Merkez",
			"Merzifon",
			"Suluova",
			"Taşova"
		]
	},
	Ankara: {
		code: 6,
		districts: [
			"Altındağ",
			"Ayaş",
			"Bala",
			"Beypazarı",
			"Çamlıdere",
			"Çankaya",
			"Çubuk",
			"Elmadağ",
			"Güdül",
			"Haymana",
			"Kalecik",
			"Kızılcahamam",
			"Nallıhan",
			"Polatlı",
			"Şereflikoçhisar",
			"Yenimahalle",
			"Gölbaşı",
			"Keçiören",
			"Mamak",
			"Sincan",
			"Kazan",
			"Akyurt",
			"Etimesgut",
			"Evren",
			"Pursaklar"
		]
	},
	Antalya: {
		code: 7,
		districts: [
			"Akseki",
			"Alanya",
			"Elmalı",
			"Finike",
			"Gazipaşa",
			"Gündoğmuş",
			"Kaş",
			"Korkuteli",
			"Kumluca",
			"Manavgat",
			"Serik",
			"Demre",
			"İbradı",
			"Kemer",
			"Aksu",
			"Döşemealtı",
			"Kepez",
			"Konyaaltı",
			"Muratpaşa"
		]
	},
	Artvin: {
		code: 8,
		districts: [
			"Ardanuç",
			"Arhavi",
			"Merkez",
			"Borçka",
			"Hopa",
			"Şavşat",
			"Yusufeli",
			"Murgul"
		]
	},
	Aydın: {
		code: 9,
		districts: [
			"Merkez",
			"Bozdoğan",
			"Efeler",
			"Çine",
			"Germencik",
			"Karacasu",
			"Koçarlı",
			"Kuşadası",
			"Kuyucak",
			"Nazilli",
			"Söke",
			"Sultanhisar",
			"Yenipazar",
			"Buharkent",
			"İncirliova",
			"Karpuzlu",
			"Köşk",
			"Didim"
		]
	},
	Balıkesir: {
		code: 10,
		districts: [
			"Altıeylül",
			"Ayvalık",
			"Merkez",
			"Balya",
			"Bandırma",
			"Bigadiç",
			"Burhaniye",
			"Dursunbey",
			"Edremit",
			"Erdek",
			"Gönen",
			"Havran",
			"İvrindi",
			"Karesi",
			"Kepsut",
			"Manyas",
			"Savaştepe",
			"Sındırgı",
			"Gömeç",
			"Susurluk",
			"Marmara"
		]
	},
	Bilecik: {
		code: 11,
		districts: [
			"Merkez",
			"Bozüyük",
			"Gölpazarı",
			"Osmaneli",
			"Pazaryeri",
			"Söğüt",
			"Yenipazar",
			"İnhisar"
		]
	},
	Bingöl: {
		code: 12,
		districts: [
			"Merkez",
			"Genç",
			"Karlıova",
			"Kiğı",
			"Solhan",
			"Adaklı",
			"Yayladere",
			"Yedisu"
		]
	},
	Bitlis: {
		code: 13,
		districts: [
			"Adilcevaz",
			"Ahlat",
			"Merkez",
			"Hizan",
			"Mutki",
			"Tatvan",
			"Güroymak"
		]
	},
	Bolu: {
		code: 14,
		districts: [
			"Merkez",
			"Gerede",
			"Göynük",
			"Kıbrıscık",
			"Mengen",
			"Mudurnu",
			"Seben",
			"Dörtdivan",
			"Yeniçağa"
		]
	},
	Burdur: {
		code: 15,
		districts: [
			"Ağlasun",
			"Bucak",
			"Merkez",
			"Gölhisar",
			"Tefenni",
			"Yeşilova",
			"Karamanlı",
			"Kemer",
			"Altınyayla",
			"Çavdır",
			"Çeltikçi"
		]
	},
	Bursa: {
		code: 16,
		districts: [
			"Gemlik",
			"İnegöl",
			"İznik",
			"Karacabey",
			"Keles",
			"Mudanya",
			"Mustafakemalpaşa",
			"Orhaneli",
			"Orhangazi",
			"Yenişehir",
			"Büyükorhan",
			"Harmancık",
			"Nilüfer",
			"Osmangazi",
			"Yıldırım",
			"Gürsu",
			"Kestel"
		]
	},
	Çanakkale: {
		code: 17,
		districts: [
			"Ayvacık",
			"Bayramiç",
			"Biga",
			"Bozcaada",
			"Çan",
			"Merkez",
			"Eceabat",
			"Ezine",
			"Gelibolu",
			"Gökçeada",
			"Lapseki",
			"Yenice"
		]
	},
	Çankırı: {
		code: 18,
		districts: [
			"Merkez",
			"Çerkeş",
			"Eldivan",
			"Ilgaz",
			"Kurşunlu",
			"Orta",
			"Şabanözü",
			"Yapraklı",
			"Atkaracalar",
			"Kızılırmak",
			"Bayramören",
			"Korgun"
		]
	},
	Çorum: {
		code: 19,
		districts: [
			"Alaca",
			"Bayat",
			"Merkez",
			"İskilip",
			"Kargı",
			"Mecitözü",
			"Ortaköy",
			"Osmancık",
			"Sungurlu",
			"Boğazkale",
			"Uğurludağ",
			"Dodurga",
			"Laçin",
			"Oğuzlar"
		]
	},
	Denizli: {
		code: 20,
		districts: [
			"Acıpayam",
			"Buldan",
			"Çal",
			"Çameli",
			"Çardak",
			"Çivril",
			"Merkez",
			"Merkezefendi",
			"Pamukkale",
			"Güney",
			"Kale",
			"Sarayköy",
			"Tavas",
			"Babadağ",
			"Bekilli",
			"Honaz",
			"Serinhisar",
			"Baklan",
			"Beyağaç",
			"Bozkurt"
		]
	},
	Diyarbakır: {
		code: 21,
		districts: [
			"Kocaköy",
			"Çermik",
			"Çınar",
			"Çüngüş",
			"Dicle",
			"Ergani",
			"Hani",
			"Hazro",
			"Kulp",
			"Lice",
			"Silvan",
			"Eğil",
			"Bağlar",
			"Kayapınar",
			"Sur",
			"Yenişehir",
			"Bismil"
		]
	},
	Edirne: {
		code: 22,
		districts: [
			"Merkez",
			"Enez",
			"Havsa",
			"İpsala",
			"Keşan",
			"Lalapaşa",
			"Meriç",
			"Uzunköprü",
			"Süloğlu"
		]
	},
	Elazığ: {
		code: 23,
		districts: [
			"Ağın",
			"Baskil",
			"Merkez",
			"Karakoçan",
			"Keban",
			"Maden",
			"Palu",
			"Sivrice",
			"Arıcak",
			"Kovancılar",
			"Alacakaya"
		]
	},
	Erzincan: {
		code: 24,
		districts: [
			"Çayırlı",
			"Merkez",
			"İliç",
			"Kemah",
			"Kemaliye",
			"Refahiye",
			"Tercan",
			"Üzümlü",
			"Otlukbeli"
		]
	},
	Erzurum: {
		code: 25,
		districts: [
			"Aşkale",
			"Çat",
			"Hınıs",
			"Horasan",
			"İspir",
			"Karayazı",
			"Narman",
			"Oltu",
			"Olur",
			"Pasinler",
			"Şenkaya",
			"Tekman",
			"Tortum",
			"Karaçoban",
			"Uzundere",
			"Pazaryolu",
			"Köprüköy",
			"Palandöken",
			"Yakutiye",
			"Aziziye"
		]
	},
	Eskişehir: {
		code: 26,
		districts: [
			"Çifteler",
			"Mahmudiye",
			"Mihalıççık",
			"Sarıcakaya",
			"Seyitgazi",
			"Sivrihisar",
			"Alpu",
			"Beylikova",
			"İnönü",
			"Günyüzü",
			"Han",
			"Mihalgazi",
			"Odunpazarı",
			"Tepebaşı"
		]
	},
	Gaziantep: {
		code: 27,
		districts: [
			"Araban",
			"İslahiye",
			"Nizip",
			"Oğuzeli",
			"Yavuzeli",
			"Şahinbey",
			"Şehitkamil",
			"Karkamış",
			"Nurdağı"
		]
	},
	Giresun: {
		code: 28,
		districts: [
			"Alucra",
			"Bulancak",
			"Dereli",
			"Espiye",
			"Eynesil",
			"Merkez",
			"Görele",
			"Keşap",
			"Şebinkarahisar",
			"Tirebolu",
			"Piraziz",
			"Yağlıdere",
			"Çamoluk",
			"Çanakçı",
			"Doğankent",
			"Güce"
		]
	},
	Gümüşhane: {
		code: 29,
		districts: ["Merkez", "Kelkit", "Şiran", "Torul", "Köse", "Kürtün"]
	},
	Hakkari: {
		code: 30,
		districts: ["Çukurca", "Merkez", "Şemdinli", "Yüksekova"]
	},
	Hatay: {
		code: 31,
		districts: [
			"Altınözü",
			"Arsuz",
			"Defne",
			"Dörtyol",
			"Hassa",
			"Antakya",
			"İskenderun",
			"Kırıkhan",
			"Payas",
			"Reyhanlı",
			"Samandağ",
			"Yayladağı",
			"Erzin",
			"Belen",
			"Kumlu"
		]
	},
	Isparta: {
		code: 32,
		districts: [
			"Atabey",
			"Eğirdir",
			"Gelendost",
			"Merkez",
			"Keçiborlu",
			"Senirkent",
			"Sütçüler",
			"Şarkikaraağaç",
			"Uluborlu",
			"Yalvaç",
			"Aksu",
			"Gönen",
			"Yenişarbademli"
		]
	},
	Mersin: {
		code: 33,
		districts: [
			"Anamur",
			"Erdemli",
			"Gülnar",
			"Mut",
			"Silifke",
			"Tarsus",
			"Aydıncık",
			"Bozyazı",
			"Çamlıyayla",
			"Akdeniz",
			"Mezitli",
			"Toroslar",
			"Yenişehir"
		]
	},
	İstanbul: {
		code: 34,
		districts: [
			"Adalar",
			"Bakırköy",
			"Beşiktaş",
			"Beykoz",
			"Beyoğlu",
			"Çatalca",
			"Eyüp",
			"Fatih",
			"Gaziosmanpaşa",
			"Kadıköy",
			"Kartal",
			"Sarıyer",
			"Silivri",
			"Şile",
			"Şişli",
			"Üsküdar",
			"Zeytinburnu",
			"Büyükçekmece",
			"Kağıthane",
			"Küçükçekmece",
			"Pendik",
			"Ümraniye",
			"Bayrampaşa",
			"Avcılar",
			"Bağcılar",
			"Bahçelievler",
			"Güngören",
			"Maltepe",
			"Sultanbeyli",
			"Tuzla",
			"Esenler",
			"Arnavutköy",
			"Ataşehir",
			"Başakşehir",
			"Beylikdüzü",
			"Çekmeköy",
			"Esenyurt",
			"Sancaktepe",
			"Sultangazi"
		]
	},
	İzmir: {
		code: 35,
		districts: [
			"Aliağa",
			"Bayındır",
			"Bergama",
			"Bornova",
			"Çeşme",
			"Dikili",
			"Foça",
			"Karaburun",
			"Karşıyaka",
			"Kemalpaşa",
			"Kınık",
			"Kiraz",
			"Menemen",
			"Ödemiş",
			"Seferihisar",
			"Selçuk",
			"Tire",
			"Torbalı",
			"Urla",
			"Beydağ",
			"Buca",
			"Konak",
			"Menderes",
			"Balçova",
			"Çiğli",
			"Gaziemir",
			"Narlıdere",
			"Güzelbahçe",
			"Bayraklı",
			"Karabağlar"
		]
	},
	Kars: {
		code: 36,
		districts: [
			"Arpaçay",
			"Digor",
			"Kağızman",
			"Merkez",
			"Sarıkamış",
			"Selim",
			"Susuz",
			"Akyaka"
		]
	},
	Kastamonu: {
		code: 37,
		districts: [
			"Abana",
			"Araç",
			"Azdavay",
			"Bozkurt",
			"Cide",
			"Çatalzeytin",
			"Daday",
			"Devrekani",
			"İnebolu",
			"Merkez",
			"Küre",
			"Taşköprü",
			"Tosya",
			"İhsangazi",
			"Pınarbaşı",
			"Şenpazar",
			"Ağlı",
			"Doğanyurt",
			"Hanönü",
			"Seydiler"
		]
	},
	Kayseri: {
		code: 38,
		districts: [
			"Bünyan",
			"Develi",
			"Felahiye",
			"İncesu",
			"Pınarbaşı",
			"Sarıoğlan",
			"Sarız",
			"Tomarza",
			"Yahyalı",
			"Yeşilhisar",
			"Akkışla",
			"Talas",
			"Kocasinan",
			"Melikgazi",
			"Hacılar",
			"Özvatan"
		]
	},
	Kırklareli: {
		code: 39,
		districts: [
			"Babaeski",
			"Demirköy",
			"Merkez",
			"Kofçaz",
			"Lüleburgaz",
			"Pehlivanköy",
			"Pınarhisar",
			"Vize"
		]
	},
	Kırşehir: {
		code: 40,
		districts: [
			"Çiçekdağı",
			"Kaman",
			"Merkez",
			"Mucur",
			"Akpınar",
			"Akçakent",
			"Boztepe"
		]
	},
	Kocaeli: {
		code: 41,
		districts: [
			"Gebze",
			"Gölcük",
			"Kandıra",
			"Karamürsel",
			"Körfez",
			"Derince",
			"Başiskele",
			"Çayırova",
			"Darıca",
			"Dilovası",
			"İzmit",
			"Kartepe"
		]
	},
	Konya: {
		code: 42,
		districts: [
			"Akşehir",
			"Beyşehir",
			"Bozkır",
			"Cihanbeyli",
			"Çumra",
			"Doğanhisar",
			"Ereğli",
			"Hadim",
			"Ilgın",
			"Kadınhanı",
			"Karapınar",
			"Kulu",
			"Sarayönü",
			"Seydişehir",
			"Yunak",
			"Akören",
			"Altınekin",
			"Derebucak",
			"Hüyük",
			"Karatay",
			"Meram",
			"Selçuklu",
			"Taşkent",
			"Ahırlı",
			"Çeltik",
			"Derbent",
			"Emirgazi",
			"Güneysınır",
			"Halkapınar",
			"Tuzlukçu",
			"Yalıhüyük"
		]
	},
	Kütahya: {
		code: 43,
		districts: [
			"Altıntaş",
			"Domaniç",
			"Emet",
			"Gediz",
			"Merkez",
			"Simav",
			"Tavşanlı",
			"Aslanapa",
			"Dumlupınar",
			"Hisarcık",
			"Şaphane",
			"Çavdarhisar",
			"Pazarlar"
		]
	},
	Malatya: {
		code: 44,
		districts: [
			"Akçadağ",
			"Arapgir",
			"Arguvan",
			"Darende",
			"Doğanşehir",
			"Hekimhan",
			"Merkez",
			"Pütürge",
			"Yeşilyurt",
			"Battalgazi",
			"Doğanyol",
			"Kale",
			"Kuluncak",
			"Yazıhan"
		]
	},
	Manisa: {
		code: 45,
		districts: [
			"Akhisar",
			"Alaşehir",
			"Demirci",
			"Gördes",
			"Kırkağaç",
			"Kula",
			"Merkez",
			"Salihli",
			"Sarıgöl",
			"Saruhanlı",
			"Selendi",
			"Soma",
			"Şehzadeler",
			"Yunusemre",
			"Turgutlu",
			"Ahmetli",
			"Gölmarmara",
			"Köprübaşı"
		]
	},
	Kahramanmaraş: {
		code: 46,
		districts: [
			"Afşin",
			"Andırın",
			"Dulkadiroğlu",
			"Onikişubat",
			"Elbistan",
			"Göksun",
			"Merkez",
			"Pazarcık",
			"Türkoğlu",
			"Çağlayancerit",
			"Ekinözü",
			"Nurhak"
		]
	},
	Mardin: {
		code: 47,
		districts: [
			"Derik",
			"Kızıltepe",
			"Artuklu",
			"Merkez",
			"Mazıdağı",
			"Midyat",
			"Nusaybin",
			"Ömerli",
			"Savur",
			"Dargeçit",
			"Yeşilli"
		]
	},
	Muğla: {
		code: 48,
		districts: [
			"Bodrum",
			"Datça",
			"Fethiye",
			"Köyceğiz",
			"Marmaris",
			"Menteşe",
			"Milas",
			"Ula",
			"Yatağan",
			"Dalaman",
			"Seydikemer",
			"Ortaca",
			"Kavaklıdere"
		]
	},
	Muş: {
		code: 49,
		districts: ["Bulanık", "Malazgirt", "Merkez", "Varto", "Hasköy", "Korkut"]
	},
	Nevşehir: {
		code: 50,
		districts: [
			"Avanos",
			"Derinkuyu",
			"Gülşehir",
			"Hacıbektaş",
			"Kozaklı",
			"Merkez",
			"Ürgüp",
			"Acıgöl"
		]
	},
	Niğde: {
		code: 51,
		districts: ["Bor", "Çamardı", "Merkez", "Ulukışla", "Altunhisar", "Çiftlik"]
	},
	Ordu: {
		code: 52,
		districts: [
			"Akkuş",
			"Altınordu",
			"Aybastı",
			"Fatsa",
			"Gölköy",
			"Korgan",
			"Kumru",
			"Mesudiye",
			"Perşembe",
			"Ulubey",
			"Ünye",
			"Gülyalı",
			"Gürgentepe",
			"Çamaş",
			"Çatalpınar",
			"Çaybaşı",
			"İkizce",
			"Kabadüz",
			"Kabataş"
		]
	},
	Rize: {
		code: 53,
		districts: [
			"Ardeşen",
			"Çamlıhemşin",
			"Çayeli",
			"Fındıklı",
			"İkizdere",
			"Kalkandere",
			"Pazar",
			"Merkez",
			"Güneysu",
			"Derepazarı",
			"Hemşin",
			"İyidere"
		]
	},
	Sakarya: {
		code: 54,
		districts: [
			"Akyazı",
			"Geyve",
			"Hendek",
			"Karasu",
			"Kaynarca",
			"Sapanca",
			"Kocaali",
			"Pamukova",
			"Taraklı",
			"Ferizli",
			"Karapürçek",
			"Söğütlü",
			"Adapazarı",
			"Arifiye",
			"Erenler",
			"Serdivan"
		]
	},
	Samsun: {
		code: 55,
		districts: [
			"Alaçam",
			"Bafra",
			"Çarşamba",
			"Havza",
			"Kavak",
			"Ladik",
			"Terme",
			"Vezirköprü",
			"Asarcık",
			"Ondokuzmayıs",
			"Salıpazarı",
			"Tekkeköy",
			"Ayvacık",
			"Yakakent",
			"Atakum",
			"Canik",
			"İlkadım"
		]
	},
	Siirt: {
		code: 56,
		districts: [
			"Baykan",
			"Eruh",
			"Kurtalan",
			"Pervari",
			"Merkez",
			"Şirvan",
			"Tillo"
		]
	},
	Sinop: {
		code: 57,
		districts: [
			"Ayancık",
			"Boyabat",
			"Durağan",
			"Erfelek",
			"Gerze",
			"Merkez",
			"Türkeli",
			"Dikmen",
			"Saraydüzü"
		]
	},
	Sivas: {
		code: 58,
		districts: [
			"Divriği",
			"Gemerek",
			"Gürün",
			"Hafik",
			"İmranlı",
			"Kangal",
			"Koyulhisar",
			"Merkez",
			"Suşehri",
			"Şarkışla",
			"Yıldızeli",
			"Zara",
			"Akıncılar",
			"Altınyayla",
			"Doğanşar",
			"Gölova",
			"Ulaş"
		]
	},
	Tekirdağ: {
		code: 59,
		districts: [
			"Çerkezköy",
			"Çorlu",
			"Ergene",
			"Hayrabolu",
			"Malkara",
			"Muratlı",
			"Saray",
			"Süleymanpaşa",
			"Kapaklı",
			"Şarköy",
			"Marmaraereğlisi"
		]
	},
	Tokat: {
		code: 60,
		districts: [
			"Almus",
			"Artova",
			"Erbaa",
			"Niksar",
			"Reşadiye",
			"Merkez",
			"Turhal",
			"Zile",
			"Pazar",
			"Yeşilyurt",
			"Başçiftlik",
			"Sulusaray"
		]
	},
	Trabzon: {
		code: 61,
		districts: [
			"Akçaabat",
			"Araklı",
			"Arsin",
			"Çaykara",
			"Maçka",
			"Of",
			"Ortahisar",
			"Sürmene",
			"Tonya",
			"Vakfıkebir",
			"Yomra",
			"Beşikdüzü",
			"Şalpazarı",
			"Çarşıbaşı",
			"Dernekpazarı",
			"Düzköy",
			"Hayrat",
			"Köprübaşı"
		]
	},
	Tunceli: {
		code: 62,
		districts: [
			"Çemişgezek",
			"Hozat",
			"Mazgirt",
			"Nazımiye",
			"Ovacık",
			"Pertek",
			"Pülümür",
			"Merkez"
		]
	},
	Şanlıurfa: {
		code: 63,
		districts: [
			"Akçakale",
			"Birecik",
			"Bozova",
			"Ceylanpınar",
			"Eyyübiye",
			"Halfeti",
			"Haliliye",
			"Hilvan",
			"Karaköprü",
			"Siverek",
			"Suruç",
			"Viranşehir",
			"Harran"
		]
	},
	Uşak: {
		code: 64,
		districts: ["Banaz", "Eşme", "Karahallı", "Sivaslı", "Ulubey", "Merkez"]
	},
	Van: {
		code: 65,
		districts: [
			"Başkale",
			"Çatak",
			"Erciş",
			"Gevaş",
			"Gürpınar",
			"İpekyolu",
			"Muradiye",
			"Özalp",
			"Tuşba",
			"Bahçesaray",
			"Çaldıran",
			"Edremit",
			"Saray"
		]
	},
	Yozgat: {
		code: 66,
		districts: [
			"Akdağmadeni",
			"Boğazlıyan",
			"Çayıralan",
			"Çekerek",
			"Sarıkaya",
			"Sorgun",
			"Şefaatli",
			"Yerköy",
			"Merkez",
			"Aydıncık",
			"Çandır",
			"Kadışehri",
			"Saraykent",
			"Yenifakılı"
		]
	},
	Zonguldak: {
		code: 67,
		districts: ["Çaycuma", "Devrek", "Ereğli", "Merkez", "Alaplı", "Gökçebey"]
	},
	Aksaray: {
		code: 68,
		districts: [
			"Ağaçören",
			"Eskil",
			"Gülağaç",
			"Güzelyurt",
			"Merkez",
			"Ortaköy",
			"Sarıyahşi"
		]
	},
	Bayburt: { code: 69, districts: ["Merkez", "Aydıntepe", "Demirözü"] },
	Karaman: {
		code: 70,
		districts: [
			"Ermenek",
			"Merkez",
			"Ayrancı",
			"Kazımkarabekir",
			"Başyayla",
			"Sarıveliler"
		]
	},
	Kırıkkale: {
		code: 71,
		districts: [
			"Delice",
			"Keskin",
			"Merkez",
			"Sulakyurt",
			"Bahşili",
			"Balışeyh",
			"Çelebi",
			"Karakeçili",
			"Yahşihan"
		]
	},
	Batman: {
		code: 72,
		districts: ["Merkez", "Beşiri", "Gercüş", "Kozluk", "Sason", "Hasankeyf"]
	},
	Şırnak: {
		code: 73,
		districts: [
			"Beytüşşebap",
			"Cizre",
			"İdil",
			"Silopi",
			"Merkez",
			"Uludere",
			"Güçlükonak"
		]
	},
	Bartın: {
		code: 74,
		districts: ["Merkez", "Kurucaşile", "Ulus", "Amasra"]
	},
	Ardahan: {
		code: 75,
		districts: ["Merkez", "Çıldır", "Göle", "Hanak", "Posof", "Damal"]
	},
	Iğdır: {
		code: 76,
		districts: ["Aralık", "Merkez", "Tuzluca", "Karakoyunlu"]
	},
	Yalova: {
		code: 77,
		districts: [
			"Merkez",
			"Altınova",
			"Armutlu",
			"Çınarcık",
			"Çiftlikköy",
			"Termal"
		]
	},
	Karabük: {
		code: 78,
		districts: [
			"Eflani",
			"Eskipazar",
			"Merkez",
			"Ovacık",
			"Safranbolu",
			"Yenice"
		]
	},
	Kilis: {
		code: 79,
		districts: ["Merkez", "Elbeyli", "Musabeyli", "Polateli"]
	},
	Osmaniye: {
		code: 80,
		districts: [
			"Bahçe",
			"Kadirli",
			"Merkez",
			"Düziçi",
			"Hasanbeyli",
			"Sumbas",
			"Toprakkale"
		]
	},
	Düzce: {
		code: 81,
		districts: [
			"Akçakoca",
			"Merkez",
			"Yığılca",
			"Cumayeri",
			"Gölyaka",
			"Çilimli",
			"Gümüşova",
			"Kaynaşlı"
		]
	}
};


export default CITY_DATA;
