import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import withErrorBoundary from "../../hoc/withErrorBoundary";
import {
  TextField,
  Button,
  Box,
  Alert,
  Typography,
  Container,
} from "@mui/material";

const LoginForm = ({ userType, from }) => {
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const { login } = useAuth();

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Geçerli bir e-posta adresi giriniz")
      .required("E-posta adresi zorunludur")
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Geçerli bir e-posta formatı giriniz"
      ),
    password: Yup.string()
      .required("Şifre zorunludur")
      .min(7, "Şifre en az 7 karakter olmalıdır"),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      setError("");
      await login(values.email, values.password, userType);
      navigate(
        userType === "buyer" ? from || "/products" : "/seller/dashboard"
      );
    } catch (err) {
      setError(err.message || "Giriş yapılırken bir hata oluştu");
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Container maxWidth="sm">
      <Formik
        initialValues={{ email: "", password: "" }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          isSubmitting,
          touched,
          errors,
          handleChange,
          handleBlur,
          values,
        }) => (
          <Form>
            <Box sx={{ mt: 3 }}>
              {error && (
                <Alert severity="error" sx={{ mb: 2 }}>
                  {error}
                </Alert>
              )}

              <Box sx={{ mb: 2 }}>
                <TextField
                  fullWidth
                  id="email"
                  name="email"
                  label="E-posta"
                  type="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.email && Boolean(errors.email)}
                  helperText={touched.email && errors.email}
                />
              </Box>

              <Box sx={{ mb: 3 }}>
                <TextField
                  fullWidth
                  id="password"
                  name="password"
                  label="Şifre"
                  type="password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.password && Boolean(errors.password)}
                  helperText={touched.password && errors.password}
                />
              </Box>

              <Button
                type="submit"
                fullWidth
                variant="contained"
                disabled={isSubmitting}
                sx={{ mb: 2 }}
              >
                {isSubmitting ? "Giriş yapılıyor..." : "Giriş Yap"}
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default withErrorBoundary(LoginForm, {
  resetButtonText: "Reload Login Form",
});
