import React from "react";
import { Link } from "react-router-dom";
import withErrorBoundary from "../hoc/withErrorBoundary";
import {
  Box,
  Container,
  Grid,
  Typography,
  Link as MuiLink,
  Button,
  Divider,
  Stack,
  Paper,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Store as StoreIcon } from "@mui/icons-material";

const CONTACT_EMAIL = process.env.REACT_APP_CONTACT_EMAIL;
const CONTACT_PHONE = process.env.REACT_APP_CONTACT_PHONE;
const CONTACT_ADDRESS = process.env.REACT_APP_CONTACT_ADDRESS;
const USTA_FRONTEND_URL = process.env.REACT_APP_USTA_FRONTEND_URL;

const LogoText = styled(Typography)(({ theme }) => ({
  fontFamily: theme.typography.logo.fontFamily,
  fontSize: "4rem",
  textDecoration: "none",
  "& .primary": {
    color: theme.palette.primary.main,
    borderBottom: `4px solid ${theme.palette.secondary.main}`,
  },
  "& .secondary": {
    color: theme.palette.secondary.main,
    borderBottom: `4px solid ${theme.palette.primary.main}`,
  },
}));

const SloganText = styled(Typography)(({ theme }) => ({
  fontFamily: theme.typography.logo.fontFamily,
  fontSize: "0.75rem",
}));

const FooterLink = styled(MuiLink)(({ theme }) => ({
  color:
    theme.palette.mode === "dark"
      ? theme.palette.grey[300]
      : theme.palette.grey[700],
  textDecoration: "none",
  fontSize: "1rem",
  fontWeight: 500,
  transition: "color 0.2s ease",
  "&:hover": {
    color: theme.palette.primary.main,
    textDecoration: "none",
  },
}));

const FooterText = styled(Typography)(({ theme }) => ({
  color:
    theme.palette.mode === "dark"
      ? theme.palette.grey[300]
      : theme.palette.grey[700],
  fontSize: "1rem",
  lineHeight: 1.6,
}));

const SocialButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  padding: theme.spacing(1, 2),
  borderRadius: theme.shape.borderRadius,
  "&:hover": {
    backgroundColor: theme.palette.secondary.main,
    transform: "translateY(-2px)",
  },
  transition: "all 0.3s ease",
}));

const FooterSection = styled(Box)(({ theme }) => ({
  "& .MuiTypography-h6": {
    color:
      theme.palette.mode === "dark"
        ? theme.palette.common.white
        : theme.palette.grey[900],
    fontSize: "1.2rem",
    fontWeight: 700,
    marginBottom: theme.spacing(3),
    position: "relative",
    "&:after": {
      content: '""',
      position: "absolute",
      bottom: -8,
      left: 0,
      width: 40,
      height: 2,
      backgroundColor: theme.palette.primary.main,
    },
  },
}));

const SellerButton = styled(Button)(({ theme }) => ({
  width: "100%",
  padding: theme.spacing(3),
  borderRadius: theme.shape.borderRadius * 2,
  backgroundColor: theme.palette.secondary.main,
  color: theme.palette.common.white,
  fontSize: "1.5rem",
  fontWeight: 700,
  textTransform: "none",
  transition: "all 0.3s ease",
  "&:hover": {
    backgroundColor: theme.palette.secondary.dark,
    transform: "translateY(-4px)",
    boxShadow: theme.shadows[8],
  },
}));

const Footer = () => {
  const handleSellerRedirect = () => {
    window.location.href = USTA_FRONTEND_URL;
  };

  return (
    <Box
      component="footer"
      sx={{
        bgcolor: "background.paper",
        borderTop: 1,
        borderColor: "divider",
        py: 6,
        px: { xs: 2, sm: 3, md: 4 },
      }}
    >
      <Container maxWidth={false}>
        <Paper
          elevation={0}
          sx={{
            p: 4,
            mb: 6,
            bgcolor: "primary.main",
            borderRadius: 4,
            textAlign: "center",
          }}
        >
          <Typography
            variant="h4"
            component="h2"
            color="common.white"
            gutterBottom
            sx={{
              fontWeight: 700,
              mb: 3,
            }}
          >
            Siz de Tasarımcı Olmak İster misiniz?
          </Typography>
          <Typography color="common.white" sx={{ mb: 4, opacity: 0.9 }}>
            Yeteneklerinizi sergileyip gelir elde etmeye başlayın!
          </Typography>
          <SellerButton
            onClick={handleSellerRedirect}
            startIcon={<StoreIcon sx={{ fontSize: 30 }} />}
          >
            Tasarımcı Portalına Git
          </SellerButton>
        </Paper>

        <Grid container spacing={4}>
          <Grid item xs={12} md={4} lg={3}>
            <FooterSection>
              <Box mb={3}>
                <LogoText
                  component={Link}
                  to="/"
                  sx={{ textDecoration: "none" }}
                >
                  <span className="primary">tasar</span>
                  <span className="secondary">al</span>
                </LogoText>
                <Stack direction="row" spacing={0.5}>
                  <SloganText variant="caption" color="primary.main">
                    Türkiye'nin En Büyük
                  </SloganText>
                  <SloganText variant="caption" color="secondary.main">
                    Tasarım
                  </SloganText>
                  <SloganText variant="caption" color="primary.main">
                    Pazarı
                  </SloganText>
                </Stack>
              </Box>
              <FooterText sx={{ mb: 2, maxWidth: 300 }}>
                Tasarımcılar ve müşteriler arasında güvenilir bir köprü
                kuruyoruz.
              </FooterText>
            </FooterSection>
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FooterSection>
              <Typography variant="h6">Hakkımızda</Typography>
              <Stack spacing={2}>
                <FooterLink component={Link} to="/about">
                  Biz Kimiz
                </FooterLink>
                <FooterLink component={Link} to="/careers">
                  Kariyer
                </FooterLink>
                <FooterLink component={Link} to="/press">
                  Basın
                </FooterLink>
              </Stack>
            </FooterSection>
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FooterSection>
              <Typography variant="h6">Destek</Typography>
              <Stack spacing={2}>
                <FooterLink component={Link} to="/help">
                  Yardım Merkezi
                </FooterLink>
                <FooterLink component={Link} to="/contact">
                  İletişim
                </FooterLink>
                <FooterLink component={Link} to="/faq">
                  Sıkça Sorulan Sorular
                </FooterLink>
              </Stack>
            </FooterSection>
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FooterSection>
              <Typography variant="h6">İletişim</Typography>
              <Stack spacing={2}>
                <FooterText>{CONTACT_ADDRESS}</FooterText>
                <FooterText>Email: {CONTACT_EMAIL}</FooterText>
                <FooterText>Tel: {CONTACT_PHONE}</FooterText>
              </Stack>
            </FooterSection>
          </Grid>
        </Grid>

        <Divider sx={{ my: 4 }} />

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexWrap: "wrap",
            gap: 2,
          }}
        >
          <FooterText>
            © {new Date().getFullYear()} Tasaral. Tüm hakları saklıdır.
          </FooterText>
          <Stack direction="row" spacing={3}>
            <FooterLink href="#" target="_blank">
              Gizlilik Politikası
            </FooterLink>
            <FooterLink href="#" target="_blank">
              Kullanım Şartları
            </FooterLink>
          </Stack>
        </Box>
      </Container>
    </Box>
  );
};

export default withErrorBoundary(Footer, {
  resetButtonText: "Reload Footer",
});
