import React from 'react';
import withErrorBoundary from '../../hoc/withErrorBoundary';
import { Card, CardContent, Typography, Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const IconWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1.5),
  borderRadius: '50%',
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '& .MuiSvgIcon-root': {
    width: 24,
    height: 24,
  },
}));

const StyledCard = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius * 2,
  boxShadow: theme.shadows[2],
  transition: 'box-shadow 0.3s ease-in-out',
  '&:hover': {
    boxShadow: theme.shadows[4],
  },
}));

const StatCard = ({ title, value, icon }) => {
  return (
    <StyledCard>
      <CardContent>
        <Box display="flex" alignItems="center">
          <IconWrapper>
            {icon}
          </IconWrapper>
          <Box ml={2}>
            <Typography variant="body2" color="text.secondary">
              {title}
            </Typography>
            <Typography variant="h5" component="div" fontWeight="600">
              {value}
            </Typography>
          </Box>
        </Box>
      </CardContent>
    </StyledCard>
  );
};

export default withErrorBoundary(StatCard, {
  resetButtonText: 'Reload Statistics'
}); 