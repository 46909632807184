import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import ApiResponse from '../utils/ApiResponse';

const useErrorHandler = () => {
  const navigate = useNavigate();

  const handleError = useCallback((error) => {
    const apiResponse = ApiResponse.fromAxiosError(error);

    // Handle authentication errors
    if (apiResponse.isUnauthorized()) {
      // Clear any auth tokens
      localStorage.removeItem('token');
      // Redirect to login
      navigate('/login');
      return;
    }

    // Handle forbidden errors
    if (apiResponse.isForbidden()) {
      navigate('/forbidden');
      return;
    }

    // Handle not found errors
    if (apiResponse.isNotFound()) {
      navigate('/not-found');
      return;
    }

    // Throw the error to be caught by ErrorBoundary
    throw error;
  }, [navigate]);

  return { handleError };
};

export default useErrorHandler; 