import React, { useState } from "react";
import { Link } from "react-router-dom";
import { productsApi } from "../../utils/api";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import AddToCartButton from "./../buttons/AddToCartButton";
import AddToWishlistButton from "./../buttons/AddToWishlistButton";
import withErrorBoundary from "../../hoc/withErrorBoundary";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Box,
  IconButton,
  Divider,
} from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledCard = styled(Card)(({ theme }) => ({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  borderRadius: theme.shape.borderRadius * 2,
  transition: "box-shadow 0.3s ease-in-out",
  "&:hover": {
    boxShadow: theme.shadows[8],
  },
  maxWidth: "100%",
}));

const ImageContainer = styled(Box)(({ theme }) => ({
  position: "relative",
  height: 220,
  overflow: "hidden",
}));

const StyledCardMedia = styled(CardMedia)(({ theme }) => ({
  height: "100%",
  objectFit: "cover",
  width: "100%",
}));

const NavigationButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  transform: "translateY(-50%)",
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  color: theme.palette.common.white,
  "&:hover": {
    backgroundColor: "rgba(0, 0, 0, 0.7)",
  },
  "&.left": {
    left: theme.spacing(1),
  },
  "&.right": {
    right: theme.spacing(1),
  },
}));

const WishlistButtonWrapper = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: theme.spacing(1),
  right: theme.spacing(1),
  zIndex: 1,
}));

const ProductCard = ({ product }) => {
  const [imageError, setImageError] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  if (!product) {
    return null;
  }

  const handlePrevImage = (e) => {
    e.preventDefault();
    setCurrentImageIndex((prev) =>
      prev === 0 ? product.productImages.length - 1 : prev - 1
    );
  };

  const handleNextImage = (e) => {
    e.preventDefault();
    setCurrentImageIndex((prev) =>
      prev === product.productImages.length - 1 ? 0 : prev + 1
    );
  };

  const formatPrice = (price) => {
    if (typeof price === "number") {
      return price.toFixed(2);
    }
    return "0.00";
  };

  return (
    <Box sx={{ height: "100%", position: "relative" }}>
      <Link
        to={`/product/${product.id}`}
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none", display: "block", height: "100%" }}
      >
        <StyledCard>
          <ImageContainer>
            <StyledCardMedia
              component="img"
              src={
                product.productImages && product.productImages.length > 0
                  ? productsApi.getProductImageUrl(product.id, currentImageIndex)
                  : productsApi.getCoverImageUrl(product.id)
              }
              alt={product.name}
              onError={(e) => {
                if (!imageError) {
                  setImageError(true);
                  e.target.src = "/placeholder-image.png";
                }
              }}
            />
            {product.productImages && product.productImages.length > 1 && (
              <>
                <NavigationButton
                  className="left"
                  onClick={handlePrevImage}
                  size="small"
                  aria-label="Previous image"
                >
                  <FaChevronLeft />
                </NavigationButton>
                <NavigationButton
                  className="right"
                  onClick={handleNextImage}
                  size="small"
                  aria-label="Next image"
                >
                  <FaChevronRight />
                </NavigationButton>
              </>
            )}
            <WishlistButtonWrapper>
              <AddToWishlistButton product={product} />
            </WishlistButtonWrapper>
          </ImageContainer>

          <CardContent
            sx={{ flexGrow: 1, display: "flex", flexDirection: "column" }}
          >
            <Box flexGrow={1}>
              <Typography
                variant="subtitle1"
                component="h3"
                gutterBottom
                sx={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: "vertical",
                }}
              >
                {product.name}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  backgroundColor: "#2C0095",
                  color: "white",
                  padding: "6px 12px",
                  borderRadius: "20px",
                  display: "inline-flex",
                  alignItems: "center",
                  fontWeight: 600,
                  mb: 1,
                  fontSize: "0.85rem",
                  letterSpacing: "0.3px",
                  boxShadow: "0 2px 4px rgba(44, 0, 149, 0.2)",
                  transition: "all 0.2s ease-in-out",
                  "&:hover": {
                    transform: "translateY(-1px)",
                    boxShadow: "0 4px 8px rgba(44, 0, 149, 0.3)",
                    backgroundColor: "#3600B8"
                  },
                  "& svg": {
                    marginRight: "4px",
                    fontSize: "0.9rem"
                  }
                }}
              >
                <svg 
                  style={{ marginRight: "6px" }} 
                  width="14" 
                  height="14" 
                  viewBox="0 0 24 24" 
                  fill="none" 
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path 
                    d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 3c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm0 14.2c-2.5 0-4.71-1.28-6-3.22.03-1.99 4-3.08 6-3.08 1.99 0 5.97 1.09 6 3.08-1.29 1.94-3.5 3.22-6 3.22z" 
                    fill="currentColor"
                  />
                </svg>
                {product.seller?.name}
              </Typography>
            </Box>

            <Divider sx={{ my: 1 }} />

            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="h6" color="primary">
                {formatPrice(product.price)} TL
              </Typography>
              <AddToCartButton product={product} />
            </Box>
          </CardContent>
        </StyledCard>
      </Link>
    </Box>
  );
};

export default withErrorBoundary(ProductCard, {
  resetButtonText: "Retry Loading Product",
});
