import React, { useState, useEffect } from "react";
import { Box, Container, Typography, Grid, useTheme } from "@mui/material";
import CategoryCard from "../components/cards/CategoryCard";
import { categoriesApi } from "../utils/api";
import withErrorBoundary from "../hoc/withErrorBoundary";

const AllCategoriesPage = () => {
  const theme = useTheme();
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await categoriesApi.getAllCategories();
        setCategories(response.data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };
    fetchCategories();
  }, []);

  return (
    <Container
      sx={{
        maxWidth: "90% !important",
      }}
    >
      <Typography
        variant="h2"
        component="h1"
        sx={{
          mb: 6,
          fontWeight: "bold",
        }}
      >
        Tüm Kategoriler
      </Typography>
      <Grid container spacing={3}>
        {categories.map((category) => (
          <Grid item xs={12} sm={6} lg={4} xl={3} key={category.id}>
            <CategoryCard category={category} />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default withErrorBoundary(AllCategoriesPage, {
  resetButtonText: "Reload Categories Page",
});
