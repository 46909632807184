import React, { createContext, useState, useContext, useEffect } from 'react';
import { authApi } from '../utils/api';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
	const [currentUser, setCurrentUser] = useState(null);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);

	useEffect(() => {
		checkAuthStatus();
	}, []);

	const checkAuthStatus = async () => {
		try {
			const response = await authApi.verifyToken();
			setCurrentUser(response.data.user);
		} catch (err) {
			setCurrentUser(null);
			setError(err.response?.data?.message || 'Authentication failed');
		} finally {
			setLoading(false);
		}
	};

	const login = async (email, password) => {
		try {
			const response = await authApi.login(email, password);
			const { user } = response.data;
			setCurrentUser(user);
			return user;
		} catch (err) {
			throw new Error(err.response?.data?.message || 'Giriş yapılırken bir hata oluştu');
		}
	};

	const logout = async () => {
		try {
			await authApi.logout();
			setCurrentUser(null);
		} catch (err) {
			console.error('Logout error:', err);
		}
	};

	const register = async (userData) => {
		try {
			const response = await authApi.register(userData);
			const { user } = response.data;
			setCurrentUser(user);
			return user;
		} catch (err) {
			throw new Error(err.response?.data?.message || 'Kayıt olurken bir hata oluştu');
		}
	};

	const updateUser = async (userData) => {
		try {
			const response = await authApi.updateUser(currentUser.id, userData);
			await checkAuthStatus();
		} catch (err) {
			throw new Error(err.response?.data?.message || 'Kullanıcı güncellenirken bir hata oluştu');
		}
	};

	const value = {
		currentUser,
		login,
		logout,
		register,
		updateUser,
		loading,
		error
	};

	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
	const context = useContext(AuthContext);
	if (!context) {
		throw new Error('useAuth must be used within an AuthProvider');
	}
	return context;
};
