import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  Box,
  Container,
  Typography,
  CircularProgress,
  useTheme,
} from "@mui/material";
import ProductList from "../components/ProductList";
import { productsApi } from "../utils/api";
import withErrorBoundary from "../hoc/withErrorBoundary";

const ProductsPage = () => {
  const { search } = useLocation();
  const theme = useTheme();
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await productsApi.getAllProducts();
        setProducts(response.data);

        const queryParams = new URLSearchParams(search);
        const searchQuery = queryParams.get("search");

        if (searchQuery) {
          setLoading(true);
          const filtered = await productsApi.getProductsByQuery(searchQuery);
          setFilteredProducts(filtered.data);
        } else {
          setFilteredProducts(response.data);
        }
      } catch (error) {
        console.error("Error fetching products:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, [search]);

  return (
    <Container 
      sx={{ 
        maxWidth: '90% !important'
      }}
    >
      <Typography
        variant="h2"
        component="h1"
        sx={{
          textAlign: "center",
          mb: 6,
          fontWeight: "bold",
        }}
      >
        Ürünler
      </Typography>

      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", py: 4 }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Typography
            variant="body1"
            sx={{
              textAlign: "center",
              mb: 6,
              color: "text.secondary",
            }}
          >
            {filteredProducts.length} ürün bulundu
          </Typography>
          <ProductList products={filteredProducts} />
        </>
      )}
    </Container>
  );
};

export default withErrorBoundary(ProductsPage, {
  resetButtonText: "Reload Products Page",
});
