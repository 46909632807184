import React, { useEffect, useState } from 'react';
import { productsApi } from '../utils/api';
import { motion, AnimatePresence } from 'framer-motion';
import withErrorBoundary from '../hoc/withErrorBoundary';
import {
  Box,
  Typography,
  Card,
  CardMedia,
  Stack,
} from '@mui/material';
import { styled } from '@mui/material/styles';

const ShowcaseContainer = styled(Box)(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.up('lg')]: {
    display: 'block',
    width: '50%',
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'hidden',
  },
}));

const ColumnContainer = styled(Box)({
  position: 'relative',
  height: '100vh',
  display: 'flex',
  justifyContent: 'space-around',
  padding: '0 32px',
});

const Column = styled(Box)({
  position: 'relative',
  width: '33.33%',
  padding: '0 16px',
  overflow: 'hidden',
});

const ProductOverlay = styled(Box)(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
  background: 'linear-gradient(to top, rgba(0,0,0,0.7), transparent)',
  backdropFilter: 'blur(2px)',
  padding: theme.spacing(1.5),
}));

const ProductShowcase = () => {
  const [products, setProducts] = useState([]);
  const [columns, setColumns] = useState([[], [], []]);

  useEffect(() => {
    const fetchProducts = async () => {
      const response = await productsApi.getAllProducts();
      if (response.success) {
        setProducts(response.data);
        distributeProducts(response.data);
      }
    };
    fetchProducts();
  }, []);

  const distributeProducts = (allProducts) => {
    const shuffled = [...allProducts].sort(() => Math.random() - 0.5);
    const newColumns = [[], [], []];
    
    shuffled.forEach((product) => {
      const columnIndex = newColumns.findIndex(col => col.length < 6);
      if (columnIndex !== -1) {
        newColumns[columnIndex].push(product);
      }
    });

    setColumns(newColumns);
  };

  const columnVariants = {
    animate: (custom) => ({
      y: custom ? [0, -1000] : [-1000, 0],
      transition: {
        y: {
          repeat: Infinity,
          repeatType: "loop",
          duration: 20,
          ease: "linear"
        }
      }
    })
  };

  return (
    <ShowcaseContainer>
      <ColumnContainer>
        {columns.map((columnProducts, columnIndex) => (
          <Column key={columnIndex}>
            <motion.div
              custom={columnIndex % 2 === 0}
              variants={columnVariants}
              animate="animate"
            >
              <Stack spacing={2} py={2}>
                {[...columnProducts, ...columnProducts].map((product, productIndex) => (
                  <motion.div
                    key={`${product._id}-${productIndex}`}
                    style={{
                      transform: 'scale(1)',
                      transition: 'transform 0.3s ease',
                      '&:hover': {
                        transform: 'scale(1.05)',
                      },
                    }}
                  >
                    <Card
                      elevation={3}
                      sx={{
                        position: 'relative',
                        borderRadius: 2,
                        overflow: 'hidden',
                        '&:hover': {
                          boxShadow: 8,
                        },
                      }}
                    >
                      <CardMedia
                        component="img"
                        image={productsApi.getCoverImageUrl(product.id)}
                        alt={product.name}
                        sx={{
                          aspectRatio: '1/1',
                          objectFit: 'cover',
                        }}
                      />
                      <ProductOverlay>
                        <Typography
                          variant="subtitle2"
                          sx={{
                            color: 'white',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                          }}
                        >
                          {product.name}
                        </Typography>
                        <Typography
                          variant="caption"
                          sx={{
                            color: 'rgba(255, 255, 255, 0.9)',
                            fontWeight: 600,
                          }}
                        >
                          {product.price} ₺
                        </Typography>
                      </ProductOverlay>
                    </Card>
                  </motion.div>
                ))}
              </Stack>
            </motion.div>
          </Column>
        ))}
      </ColumnContainer>
    </ShowcaseContainer>
  );
};

export default withErrorBoundary(ProductShowcase, {
  resetButtonText: 'Retry Loading Showcase'
}); 