import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import withErrorBoundary from "../../hoc/withErrorBoundary";
import {
  Box,
  Typography,
  TextField,
  Button,
  IconButton,
  Paper,
} from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledStarIcon = styled(FontAwesomeIcon)(({ theme, active }) => ({
  width: "2rem !important",
  height: "2rem !important",
  color: active ? theme.palette.warning.main : theme.palette.grey[300],
  transition: theme.transitions.create("color", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const ReviewForm = ({ review, onSubmit, onCancel }) => {
  const [formData, setFormData] = useState({
    rating: review.rating,
    comment: review.comment,
  });
  const [hoveredRating, setHoveredRating] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({ ...review, ...formData });
  };

  const handleStarClick = (rating) => {
    setFormData((prev) => ({ ...prev, rating }));
  };

  return (
    <Paper elevation={0} sx={{ p: 3 }}>
      <Box component="form" onSubmit={handleSubmit} sx={{ "& > *": { mb: 3 } }}>
        <Box>
          <Typography
            variant="subtitle2"
            component="label"
            sx={{ mb: 1, display: "block" }}
          >
            Değerlendirme
          </Typography>
          <Box sx={{ display: "flex", gap: 0.5 }}>
            {[1, 2, 3, 4, 5].map((star) => (
              <IconButton
                key={star}
                onMouseEnter={() => setHoveredRating(star)}
                onMouseLeave={() => setHoveredRating(null)}
                onClick={() => handleStarClick(star)}
                size="large"
                sx={{ p: 0.5 }}
              >
                <StyledStarIcon
                  icon={faStar}
                  active={
                    (
                      hoveredRating !== null
                        ? star <= hoveredRating
                        : star <= formData.rating
                    )
                      ? 1
                      : 0
                  }
                />
              </IconButton>
            ))}
          </Box>
        </Box>

        <Box>
          <TextField
            fullWidth
            id="comment"
            label="Yorum"
            multiline
            rows={4}
            value={formData.comment}
            onChange={(e) =>
              setFormData((prev) => ({ ...prev, comment: e.target.value }))
            }
            placeholder="Ürün hakkında düşüncelerinizi yazın..."
          />
        </Box>

        <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}>
          <Button onClick={onCancel} variant="outlined" color="inherit">
            İptal
          </Button>
          <Button type="submit" variant="contained">
            Kaydet
          </Button>
        </Box>
      </Box>
    </Paper>
  );
};

export default withErrorBoundary(ReviewForm, {
  resetButtonText: "Reload Review Form",
});
