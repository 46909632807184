import React, { useState, useEffect } from "react";
import ProductList from "../ProductList";
import { wishlistApi } from "../../utils/api";
import withErrorBoundary from "../../hoc/withErrorBoundary";
import { Box, Typography, CircularProgress, Container } from "@mui/material";

const WishlistPanel = () => {
  const [wishlistItems, setWishlistItems] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchWishlist = async () => {
      try {
        const response = await wishlistApi.getWishlist();
        console.log(response);
        setWishlistItems(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching wishlist:", error);
        setLoading(false);
      }
    };

    fetchWishlist();
  }, []);

  const removeFromWishlist = async (productId) => {
    try {
      await wishlistApi.removeFromWishlist(productId);
      setWishlistItems(wishlistItems.filter((item) => item.id !== productId));
    } catch (error) {
      console.error("Error removing from wishlist:", error);
    }
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="200px"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container>
      {wishlistItems.length === 0 ? (
        <Box py={4} textAlign="center">
          <Typography color="text.secondary">
            Favorilerinizde henüz ürün bulunmuyor.
          </Typography>
        </Box>
      ) : (
        <ProductList products={wishlistItems.map((item) => item.product)} />
      )}
    </Container>
  );
};

export default withErrorBoundary(WishlistPanel, {
  resetButtonText: "Reload Wishlist",
});
