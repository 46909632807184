import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Container,
  Box,
  Paper,
  Avatar,
  Typography,
  Chip,
  Tabs,
  Tab,
  Grid,
  CircularProgress,
  Stack,
  Divider,
  Link,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import {
  Star as StarIcon,
  LocationOn as LocationIcon,
  VerifiedUser as VerifiedIcon,
  ShoppingBag as ShoppingBagIcon,
  Phone as PhoneIcon,
  Email as EmailIcon,
  Work as WorkIcon,
  Group as GroupIcon,
  Link as LinkIcon,
  ExpandMore as ExpandMoreIcon,
  Description as DescriptionIcon,
  Info as InfoIcon,
} from "@mui/icons-material";
import {
  sellersApi,
  productsApi,
  reviewsApi,
  postsApi,
  statsApi,
} from "../utils/api";
import ProductCard from "../components/cards/ProductCard";
import ReviewCard from "../components/cards/ReviewCard";
import PostCard from "../components/cards/PostCard";
import withErrorBoundary from "../hoc/withErrorBoundary";

const SellerDetailPage = () => {
  const { id } = useParams();
  const [seller, setSeller] = useState(null);
  const [products, setProducts] = useState({ featured: [], all: [] });
  const [reviews, setReviews] = useState([]);
  const [posts, setPosts] = useState([]);
  const [averageRating, setAverageRating] = useState(0);
  const [totalReviews, setTotalReviews] = useState(0);
  const [numberOfProducts, setNumberOfProducts] = useState(0);
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState(0);
  const [expandedPanel, setExpandedPanel] = useState(false);

  useEffect(() => {
    const fetchSellerData = async () => {
      try {
        const [sellerRes, productsRes, reviewsRes, postsRes, statsRes] =
          await Promise.all([
            sellersApi.getSellerById(id),
            productsApi.getSellerProducts(id),
            reviewsApi.getSellerReviews(id),
            postsApi.getSellerPosts(id),
            statsApi.getSellerAverageRating(id),
          ]);

        setSeller(sellerRes.data);
        setProducts(productsRes.data);
        setReviews(reviewsRes.data);
        setPosts(postsRes.data);
        setAverageRating(statsRes.data?.averageRating || 0);
        setTotalReviews(statsRes.data?.totalReviews || 0);
        setNumberOfProducts(productsRes.data.length);
      } catch (error) {
        console.error("Error fetching seller data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchSellerData();
  }, [id]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handlePanelChange = (panel) => (event, isExpanded) => {
    setExpandedPanel(isExpanded ? panel : false);
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="60vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ minHeight: "100vh", bgcolor: "background.default" }}>
      {/* Cover Image Section */}
      <Box
        sx={{
          height: 320,
          background: (theme) =>
            seller.useDefaultCover
              ? `linear-gradient(to right, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`
              : "none",
          position: "relative",
          overflow: "hidden",
        }}
      >
        {seller.coverImage && (
          <Box
            component="img"
            src={sellersApi.getSellerCoverImageUrl(id)}
            alt="cover"
            sx={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
          />
        )}
      </Box>

      {/* Profile Section */}
      <Container
        maxWidth="lg"
        sx={{ mt: -12, position: "relative", zIndex: 1 }}
      >
        <Paper
          elevation={3}
          sx={{
            p: 4,
            borderRadius: 2,
            overflow: "hidden",
          }}
        >
          <Stack
            direction={{ xs: "column", md: "row" }}
            spacing={3}
            alignItems={{ xs: "center", md: "flex-start" }}
          >
            {/* Profile Image */}
            <Avatar
              src={sellersApi.getSellerProfileImageUrl(id)}
              alt={seller.name}
              sx={{
                width: 128,
                height: 128,
                border: 4,
                borderColor: "background.paper",
                flexShrink: 0,
              }}
            />

            {/* Profile Info */}
            <Box flex={1} width="100%">
              {/* Basic Information */}
              <Stack
                direction="row"
                alignItems="center"
                spacing={1}
                justifyContent={{ xs: "center", md: "flex-start" }}
                mb={1}
                sx={{ flexWrap: "wrap" }}
              >
                <Typography
                  variant="h4"
                  component="h1"
                  fontWeight="bold"
                  sx={{
                    wordBreak: "break-word",
                    fontSize: { xs: "1.5rem", sm: "2rem", md: "2.125rem" },
                  }}
                >
                  {seller.name}
                </Typography>
                <VerifiedIcon color="primary" />
              </Stack>

              {/* Workshop Information */}
              <Typography
                variant="h6"
                color="primary"
                mb={1}
                textAlign={{ xs: "center", md: "left" }}
                sx={{
                  wordBreak: "break-word",
                  fontSize: { xs: "1.1rem", sm: "1.25rem" },
                }}
              >
                {seller.workshopName}
              </Typography>

              {/* Stats */}
              <Stack
                direction="row"
                flexWrap="wrap"
                gap={2}
                justifyContent={{ xs: "center", md: "flex-start" }}
                mb={2}
              >
                <Stack direction="row" alignItems="center" spacing={0.5}>
                  <LocationIcon color="action" fontSize="small" />
                  <Typography
                    color="text.secondary"
                    sx={{
                      fontSize: { xs: "0.875rem", sm: "1rem" },
                      maxWidth: { xs: "150px", sm: "200px" },
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {`${seller.district ? seller.district : ""}, ${
                      seller.city ? seller.city : ""
                    }`}
                  </Typography>
                </Stack>
                <Stack direction="row" alignItems="center" spacing={0.5}>
                  <StarIcon color="warning" fontSize="small" />
                  <Typography
                    color="text.secondary"
                    sx={{ fontSize: { xs: "0.875rem", sm: "1rem" } }}
                  >
                    {averageRating} ({totalReviews} değerlendirme)
                  </Typography>
                </Stack>
                <Stack direction="row" alignItems="center" spacing={0.5}>
                  <ShoppingBagIcon color="action" fontSize="small" />
                  <Typography
                    color="text.secondary"
                    sx={{ fontSize: { xs: "0.875rem", sm: "1rem" } }}
                  >
                    {numberOfProducts} ürün
                  </Typography>
                </Stack>
                <Stack direction="row" alignItems="center" spacing={0.5}>
                  <WorkIcon color="action" fontSize="small" />
                  <Typography
                    color="text.secondary"
                    sx={{ fontSize: { xs: "0.875rem", sm: "1rem" } }}
                  >
                    {seller.experience} yıl deneyim
                  </Typography>
                </Stack>
              </Stack>

              {/* Tags */}
              <Box
                sx={{
                  mb: 2,
                  maxHeight: "100px",
                  overflow: "auto",
                  scrollbarWidth: "thin",
                  "&::-webkit-scrollbar": {
                    width: "6px",
                    height: "6px",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "rgba(0,0,0,.2)",
                    borderRadius: "3px",
                  },
                }}
              >
                <Stack
                  direction="row"
                  flexWrap="wrap"
                  gap={1}
                  justifyContent={{ xs: "center", md: "flex-start" }}
                >
                  {seller.customTags?.map((tag, index) => (
                    <Chip
                      key={index}
                      label={tag}
                      color="primary"
                      variant="outlined"
                      size="small"
                      sx={{
                        maxWidth: "200px",
                        "& .MuiChip-label": {
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        },
                      }}
                    />
                  ))}
                </Stack>
              </Box>

              {/* Description and Additional Info Accordions */}
              <Box mb={2}>
                {seller.description && (
                  <Accordion
                    expanded={expandedPanel === "description"}
                    onChange={handlePanelChange("description")}
                    sx={{
                      "&:before": { display: "none" },
                      boxShadow: "none",
                      backgroundColor: "background.default",
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      sx={{
                        padding: 1,
                        minHeight: "48px",
                        "& .MuiAccordionSummary-content": {
                          margin: "4px 0",
                        },
                      }}
                    >
                      <Stack direction="row" spacing={1} alignItems="center">
                        <DescriptionIcon color="primary" fontSize="small" />
                        <Typography variant="subtitle1" fontWeight="medium">
                          Atölye Açıklaması
                        </Typography>
                      </Stack>
                    </AccordionSummary>
                    <AccordionDetails
                      sx={{
                        pt: 0,
                        px: 3,
                        maxHeight: "300px",
                        overflow: "auto",
                        scrollbarWidth: "thin",
                        "&::-webkit-scrollbar": {
                          width: "6px",
                        },
                        "&::-webkit-scrollbar-thumb": {
                          backgroundColor: "rgba(0,0,0,.2)",
                          borderRadius: "3px",
                        },
                      }}
                    >
                      <Typography
                        variant="body1"
                        color="text.secondary"
                        sx={{
                          whiteSpace: "pre-line",
                          textAlign: "justify",
                          wordBreak: "break-word",
                        }}
                      >
                        {seller.description}
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                )}

                {seller.additionalInfo && (
                  <Accordion
                    expanded={expandedPanel === "additionalInfo"}
                    onChange={handlePanelChange("additionalInfo")}
                    sx={{
                      "&:before": { display: "none" },
                      boxShadow: "none",
                      backgroundColor: "background.default",
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      sx={{
                        padding: 1,
                        minHeight: "48px",
                        "& .MuiAccordionSummary-content": {
                          margin: "4px 0",
                        },
                      }}
                    >
                      <Stack direction="row" spacing={1} alignItems="center">
                        <InfoIcon color="primary" fontSize="small" />
                        <Typography variant="subtitle1" fontWeight="medium">
                          Ek Bilgiler
                        </Typography>
                      </Stack>
                    </AccordionSummary>
                    <AccordionDetails
                      sx={{
                        pt: 0,
                        px: 3,
                        maxHeight: "300px",
                        overflow: "auto",
                        scrollbarWidth: "thin",
                        "&::-webkit-scrollbar": {
                          width: "6px",
                        },
                        "&::-webkit-scrollbar-thumb": {
                          backgroundColor: "rgba(0,0,0,.2)",
                          borderRadius: "3px",
                        },
                      }}
                    >
                      <Typography
                        variant="body1"
                        color="text.secondary"
                        sx={{
                          whiteSpace: "pre-line",
                          textAlign: "justify",
                          wordBreak: "break-word",
                        }}
                      >
                        {seller.additionalInfo}
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                )}
              </Box>

              {/* Contact Information */}
              <Stack spacing={1} mb={2}>
                {seller.phone && (
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <PhoneIcon color="action" fontSize="small" />
                    <Typography
                      sx={{
                        wordBreak: "break-word",
                        fontSize: { xs: "0.875rem", sm: "1rem" },
                      }}
                    >
                      {seller.phone}
                    </Typography>
                  </Stack>
                )}
                <Stack direction="row" alignItems="center" spacing={1}>
                  <EmailIcon color="action" fontSize="small" />
                  <Typography
                    sx={{
                      wordBreak: "break-word",
                      fontSize: { xs: "0.875rem", sm: "1rem" },
                    }}
                  >
                    {seller.email}
                  </Typography>
                </Stack>
                {seller.portfolioLink && (
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <LinkIcon color="action" fontSize="small" />
                    <Link
                      href={seller.portfolioLink}
                      target="_blank"
                      rel="noopener"
                      sx={{
                        wordBreak: "break-word",
                        fontSize: { xs: "0.875rem", sm: "1rem" },
                      }}
                    >
                      Portfolio
                    </Link>
                  </Stack>
                )}
              </Stack>

              {/* Workshop Details */}
              <Box mb={2}>
                <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
                  Atölye Bilgileri
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ wordBreak: "break-word" }}
                    >
                      <strong>Atölye Türü:</strong> {seller.workshopType}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ wordBreak: "break-word" }}
                    >
                      <strong>Hedef Kitle:</strong> {seller.targetAudience}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Stack>
        </Paper>

        {/* Tabs Section */}
        <Box sx={{ mt: 4 }}>
          <Paper elevation={2}>
            <Tabs
              value={activeTab}
              onChange={handleTabChange}
              variant="fullWidth"
              aria-label="seller content tabs"
              sx={{
                "& .MuiTab-root": {
                  textTransform: "none",
                },
              }}
            >
              <Tab label="Gönderiler" />
              <Tab label="Ürünler" />
              <Tab label="Değerlendirmeler" />
            </Tabs>

            <Box sx={{ p: 3 }}>
              {/* Posts Panel */}
              {activeTab === 0 && (
                <Stack spacing={2}>
                  {posts && posts.length > 0 ? (
                    posts.map((post) => <PostCard key={post.id} post={post} />)
                  ) : (
                    <Typography color="text.secondary" textAlign="center">
                      Henüz gönderi bulunmamaktadır.
                    </Typography>
                  )}
                </Stack>
              )}

              {/* Products Panel */}
              {activeTab === 1 && (
                <Grid container spacing={3}>
                  {products && products.length > 0 ? (
                    products.map((product) => (
                      <Grid item xs={12} sm={6} md={4} key={product.id}>
                        <ProductCard product={product} />
                      </Grid>
                    ))
                  ) : (
                    <Grid item xs={12}>
                      <Typography color="text.secondary" textAlign="center">
                        Henüz ürün bulunmamaktadır.
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              )}

              {/* Reviews Panel */}
              {activeTab === 2 && (
                <Stack spacing={2}>
                  {reviews && reviews.length > 0 ? (
                    reviews.map((review) => (
                      <ReviewCard
                        key={review.id}
                        review={review}
                        isBuyerNameVisible={true}
                        isProductNameVisible={true}
                        isEditClickable={false}
                        isEditVisible={false}
                        onEditClick={() => {}}
                        isProductClickable={true}
                        isProductVisible={true}
                      />
                    ))
                  ) : (
                    <Typography color="text.secondary" textAlign="center">
                      Henüz değerlendirme bulunmamaktadır.
                    </Typography>
                  )}
                </Stack>
              )}
            </Box>
          </Paper>
        </Box>
      </Container>
    </Box>
  );
};

export default withErrorBoundary(SellerDetailPage, {
  resetButtonText: "Reload Seller Details",
});
