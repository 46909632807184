class ApiResponse {
  constructor(success, message, data = null, statusCode = 200) {
    this.success = success;
    this.message = message;
    this.data = data;
    this.statusCode = statusCode;
  }

  static fromAxiosResponse(response) {
    return new ApiResponse(
      response.data.success,
      response.data.message,
      response.data.data,
      response.status
    );
  }

  static fromAxiosError(error) {
    const response = error.response;
    if (response) {
      // Server responded with a status code outside of 2xx
      return new ApiResponse(
        false,
        response.data.message || 'An error occurred',
        response.data.data,
        response.status
      );
    } else if (error.request) {
      // Request was made but no response received
      return new ApiResponse(
        false,
        'No response from server',
        null,
        500
      );
    } else {
      // Error in setting up the request
      return new ApiResponse(
        false,
        error.message || 'Request configuration error',
        null,
        500
      );
    }
  }

  isSuccess() {
    return this.success;
  }

  isError() {
    return !this.success;
  }

  isUnauthorized() {
    return this.statusCode === 401;
  }

  isForbidden() {
    return this.statusCode === 403;
  }

  isNotFound() {
    return this.statusCode === 404;
  }
}

export default ApiResponse; 