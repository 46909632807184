import React, { useState, useEffect } from "react";
import { reviewsApi } from "../../utils/api";
import ReviewCard from "../cards/ReviewCard";
import ReviewForm from "../forms/ReviewForm";
import Modal from "../Modal";
import withErrorBoundary from "../../hoc/withErrorBoundary";
import {
  Box,
  Typography,
  CircularProgress,
  Stack,
  Container,
} from "@mui/material";

const ReviewsPanel = () => {
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editingReview, setEditingReview] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    fetchReviews();
  }, []);

  const fetchReviews = async () => {
    try {
      const response = await reviewsApi.getBuyerReviews();
      setReviews(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching reviews:", error);
      setLoading(false);
    }
  };

  const handleEditClick = (review) => {
    setEditingReview(review);
    setIsModalOpen(true);
  };

  const handleEditSubmit = async (updatedReview) => {
    try {
      await reviewsApi.updateReview(updatedReview.id, {
        rating: updatedReview.rating,
        comment: updatedReview.comment,
      });

      setReviews((prevReviews) =>
        prevReviews.map((review) =>
          review.id === updatedReview.id ? updatedReview : review
        )
      );

      setIsModalOpen(false);
      setEditingReview(null);
    } catch (error) {
      console.error("Error updating review:", error);
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setEditingReview(null);
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="200px"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container>
      {reviews.length === 0 ? (
        <Box py={4} textAlign="center">
          <Typography color="text.secondary">
            Henüz bir değerlendirme yapmadınız.
          </Typography>
        </Box>
      ) : (
        <Stack spacing={3}>
          {reviews.map((review) => (
            <ReviewCard
              key={review.id}
              review={review}
              isBuyerNameVisible={false}
              isProductNameVisible={true}
              isEditClickable={true}
              isEditVisible={true}
              onEditClick={handleEditClick}
              isProductClickable={true}
              isProductVisible={true}
            />
          ))}
        </Stack>
      )}

      {isModalOpen && editingReview && (
        <Modal
          isOpen={isModalOpen}
          onClose={handleCancel}
          title="Değerlendirmeyi Düzenle"
        >
          <ReviewForm
            review={editingReview}
            onSubmit={handleEditSubmit}
            onCancel={handleCancel}
          />
        </Modal>
      )}
    </Container>
  );
};

export default withErrorBoundary(ReviewsPanel, {
  resetButtonText: "Reload Reviews",
});
