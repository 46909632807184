import React, { useState, useEffect } from "react";
import { ordersApi } from "../../utils/api";
import OrderCard from "../cards/OrderCard";
import withErrorBoundary from "../../hoc/withErrorBoundary";
import {
  Box,
  Typography,
  CircularProgress,
  Stack,
  Container,
} from "@mui/material";

const OrdersPanel = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await ordersApi.getBuyerOrders();
        setOrders(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching orders:", error);
        setLoading(false);
      }
    };

    fetchOrders();
  }, []);

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="16rem"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container>
      {orders.length === 0 ? (
        <Box py={4} textAlign="center">
          <Typography color="text.secondary">
            Henüz hiç siparişiniz bulunmuyor.
          </Typography>
        </Box>
      ) : (
        <Stack spacing={2}>
          {orders.map((order) => (
            <OrderCard key={order.id} order={order} />
          ))}
        </Stack>
      )}
    </Container>
  );
};

export default withErrorBoundary(OrdersPanel, {
  resetButtonText: "Reload Orders",
});
