import React, { useState } from "react";
import withErrorBoundary from "../../hoc/withErrorBoundary";
import { Box, TextField, Button, Paper } from "@mui/material";

const ProfileForm = ({ currentUser, onSubmit, onCancel }) => {
  const [formData, setFormData] = useState({
    name: currentUser?.name || "",
    email: currentUser?.email || "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
  };

  return (
    <Paper elevation={0} sx={{ p: 3 }}>
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          "& > *": { mb: 2 },
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <TextField
          fullWidth
          label="Ad Soyad"
          value={formData.name}
          onChange={(e) => setFormData({ ...formData, name: e.target.value })}
        />

        <TextField
          fullWidth
          label="E-posta"
          type="email"
          value={formData.email}
          onChange={(e) => setFormData({ ...formData, email: e.target.value })}
        />

        <Box sx={{ display: "flex", gap: 2, mt: 2 }}>
          <Button type="submit" variant="contained" fullWidth>
            Kaydet
          </Button>
          <Button
            onClick={onCancel}
            variant="outlined"
            color="inherit"
            fullWidth
          >
            İptal
          </Button>
        </Box>
      </Box>
    </Paper>
  );
};

export default withErrorBoundary(ProfileForm, { resetButtonText: "Retry" });
