import { createTheme } from "@mui/material/styles";

// Color palette
export const colors = {
  primary: {
    light: "#2C0095", // Bright Blue
    dark: "#804aff",
  },
  secondary: {
    light: "#FF5C00", // Emerald
    dark: "#ffae80",
  },
  accent: {
    light: "#F59E0B", // Amber
    dark: "#FBBF24",
  },
  background: {
    light: "#F3F4F6",
    dark: "#1F2937",
  },
  text: {
    light: {
      primary: "#1F2937", // Dark gray for primary text
      secondary: "#4B5563", // Medium gray for secondary text
      disabled: "#9CA3AF", // Light gray for disabled text
      hint: "#6B7280", // Subtle gray for hints/captions
    },
    dark: {
      primary: "#F9FAFB", // Almost white for primary text
      secondary: "#D1D5DB", // Light gray for secondary text
      disabled: "#6B7280", // Medium gray for disabled text
      hint: "#9CA3AF", // Gray for hints/captions
    },
  },
  card: {
    light: "#FFFFFF",
    dark: "#374151",
  },
};

// Typography - Updated with more readable fonts
const fontFamily = {
  sans: ["Plus Jakarta Sans", "system-ui", "sans-serif"],
  display: ["DM Sans", "system-ui", "sans-serif"],
  logo: ["Alfa Slab One", "sans-serif"], // Keeping logo font as requested
};

// Updated spacing for more balanced proportions
const spacing = {
  xs: "0.375rem", // 6px
  sm: "0.75rem", // 12px
  md: "1.25rem", // 20px
  lg: "2rem", // 32px
  xl: "3rem", // 48px
  "2xl": "4rem", // 64px
};

// Breakpoints
const breakpoints = {
  sm: "640px",
  md: "768px",
  lg: "1024px",
  xl: "1280px",
};

export const baseTheme = {
  typography: {
    fontFamily: fontFamily.sans.join(","),
    h1: {
      fontFamily: fontFamily.display.join(","),
      fontSize: "clamp(2rem, 5vw, 2.75rem)",
      fontWeight: 700,
      letterSpacing: "-0.02em",
      lineHeight: 1.2,
    },
    h2: {
      fontFamily: fontFamily.display.join(","),
      fontSize: "clamp(1.75rem, 4vw, 2.25rem)",
      fontWeight: 700,
      letterSpacing: "-0.015em",
      lineHeight: 1.3,
    },
    h3: {
      fontFamily: fontFamily.display.join(","),
      fontSize: "clamp(1.5rem, 3vw, 1.875rem)",
      fontWeight: 600,
      letterSpacing: "-0.01em",
      lineHeight: 1.4,
    },
    h4: {
      fontFamily: fontFamily.display.join(","),
      fontSize: "clamp(1.25rem, 2.5vw, 1.5rem)",
      fontWeight: 600,
      lineHeight: 1.4,
    },
    h5: {
      fontFamily: fontFamily.display.join(","),
      fontSize: "clamp(1.125rem, 2vw, 1.25rem)",
      fontWeight: 600,
      lineHeight: 1.5,
    },
    h6: {
      fontFamily: fontFamily.display.join(","),
      fontSize: "1.125rem",
      fontWeight: 600,
      lineHeight: 1.5,
    },
    body1: {
      fontSize: "1rem",
      lineHeight: 1.6,
      letterSpacing: "0.01em",
    },
    body2: {
      fontFamily: fontFamily.sans.join(","),
      fontSize: "0.9375rem",
      fontWeight: 500,
      lineHeight: 1.6,
      letterSpacing: "0.01em",
    },
    logo: {
      fontFamily: fontFamily.logo.join(","),
    },
  },
  spacing: (factor) => {
    const spacingValues = {
      1: spacing.xs,
      2: spacing.sm,
      4: spacing.md,
      6: spacing.lg,
      8: spacing.xl,
      12: spacing["2xl"],
    };
    return spacingValues[factor] || `${factor * 0.25}rem`;
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: parseInt(breakpoints.sm),
      md: parseInt(breakpoints.md),
      lg: parseInt(breakpoints.lg),
      xl: parseInt(breakpoints.xl),
    },
  },
  shape: {
    borderRadius: 12,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          borderRadius: 12,
          fontFamily: fontFamily.sans.join(","),
          fontSize: "0.9375rem",
          fontWeight: 600,
          padding: "0.625rem 1.25rem",
          transition: "all 0.2s ease-in-out",
        },
        contained: {
          "&.MuiButton-containedPrimary": {
            color: "#FFFFFF",
            "&:hover": {
              color: "#FFFFFF",
            },
          },
          "&.MuiButton-containedSecondary": {
            color: "#FFFFFF",
            "&:hover": {
              color: "#FFFFFF",
            },
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 16,
          boxShadow: "0 4px 20px rgba(0, 0, 0, 0.08)",
          transition: "transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out",
          "&:hover": {
            transform: "translateY(-2px)",
            boxShadow: "0 6px 24px rgba(0, 0, 0, 0.12)",
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root": {
            borderRadius: 12,
            transition: "all 0.2s ease-in-out",
            "&:hover": {
              backgroundColor: "rgba(0, 0, 0, 0.02)",
            },
          },
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          borderRadius: 12,
          padding: "12px 16px",
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          fontWeight: 500,
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            backgroundColor: colors.primary.light,
            color: "#FFFFFF",
            "&:hover": {
              backgroundColor: colors.primary.dark,
              color: "#FFFFFF",
            },
          },
        },
      },
    },
  },
  palette: {
    mode: "light",
    primary: {
      main: colors.primary.light,
      light: colors.primary.light,
      dark: colors.primary.dark,
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: colors.secondary.light,
      light: colors.secondary.light,
      dark: colors.secondary.dark,
      contrastText: "#FFFFFF",
    },
    background: {
      default: colors.background.light,
      paper: colors.card.light,
    },
    text: {
      primary: colors.text.light.primary,
      secondary: colors.text.light.secondary,
      disabled: colors.text.light.disabled,
      hint: colors.text.light.hint,
    },
    action: {
      active: colors.text.light.primary,
      hover: "rgba(0, 0, 0, 0.04)",
      selected: "rgba(0, 0, 0, 0.08)",
      disabled: colors.text.light.disabled,
      disabledBackground: "rgba(0, 0, 0, 0.12)",
    },
    divider: "rgba(0, 0, 0, 0.12)",
  },
};

const theme = createTheme(baseTheme);
export default theme;
