import React from "react";
import { FaTrash, FaMinus, FaPlus } from "react-icons/fa";
import { productsApi } from "../../utils/api";
import { useNavigate } from "react-router-dom";
import withErrorBoundary from "../../hoc/withErrorBoundary";
import { Card, CardMedia, Typography, Box, IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledCard = styled(Card)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(2),
  padding: theme.spacing(2),
  cursor: "pointer",
  borderRadius: theme.shape.borderRadius * 2,
  transition: "all 0.2s ease-in-out",
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
  },
}));

const ProductImage = styled(CardMedia)(({ theme }) => ({
  width: 96,
  height: 96,
  borderRadius: theme.shape.borderRadius,
  flexShrink: 0,
}));

const QuantityButton = styled(IconButton)(({ theme }) => ({
  padding: theme.spacing(1),
  backgroundColor: theme.palette.grey[100],
  "&:hover": {
    backgroundColor: theme.palette.grey[200],
  },
}));

const DeleteButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.error.main,
  "&:hover": {
    backgroundColor: theme.palette.error.light,
    color: theme.palette.error.dark,
  },
}));

const CartItemCard = ({ item, onUpdateQuantity, onRemove }) => {
  const navigate = useNavigate();

  const handleCardClick = (e) => {
    // Prevent navigation if clicking on controls
    if (e.target.closest(".cart-controls")) {
      return;
    }
    navigate(`/product/${item.id}`);
  };

  return (
    <StyledCard onClick={handleCardClick}>
      <ProductImage
        component="img"
        src={
          item.productImages && item.productImages.length > 0
            ? productsApi.getProductImageUrl(item.id, 0)
            : productsApi.getCoverImageUrl(item.id)
        }
        alt={item.name}
      />
      <Box flex={1}>
        <Typography variant="h6" gutterBottom>
          {item.name}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {item.sellerName}
        </Typography>
        <Typography variant="h6" color="primary" gutterBottom>
          {item.price} TL
        </Typography>
      </Box>
      <Box className="cart-controls" display="flex" alignItems="center" gap={1}>
        <QuantityButton
          onClick={(e) => {
            e.stopPropagation();
            onUpdateQuantity(item.id, item.quantity - 1);
          }}
          size="small"
          aria-label="Decrease quantity"
        >
          <FaMinus size={12} />
        </QuantityButton>
        <Typography variant="body1" sx={{ width: 32, textAlign: "center" }}>
          {item.quantity}
        </Typography>
        <QuantityButton
          onClick={(e) => {
            e.stopPropagation();
            onUpdateQuantity(item.id, item.quantity + 1);
          }}
          size="small"
          aria-label="Increase quantity"
        >
          <FaPlus size={12} />
        </QuantityButton>
      </Box>
      <DeleteButton
        className="cart-controls"
        onClick={(e) => {
          e.stopPropagation();
          onRemove(item.id);
        }}
        size="small"
        aria-label="Remove item"
      >
        <FaTrash size={18} />
      </DeleteButton>
    </StyledCard>
  );
};

export default withErrorBoundary(CartItemCard, {
  resetButtonText: "Retry Loading Cart Item",
});
