import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import withErrorBoundary from "../hoc/withErrorBoundary";
import { categoriesApi } from "../utils/api";
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Avatar,
  Typography,
  Collapse,
  Link as MuiLink,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

const StyledListItem = styled(ListItem)(({ theme, selected }) => ({
  borderRadius: theme.shape.borderRadius,
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
  },
  ...(selected && {
    "&::after": {
      content: '""',
      position: "absolute",
      bottom: 0,
      left: 0,
      width: "100%",
      height: 2,
      backgroundColor: theme.palette.primary.main,
    },
  }),
}));

const CategoryItem = ({ category, child = null, depth = 0 }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const location = useLocation();
  const hasChildren = category.children && category.children.length > 0;
  const isSelected = child
    ? location.pathname === `/category/${category.id}/subcategory/${child.id}`
    : location.pathname === `/category/${category.id}`;

  if (child) {
    return (
      <StyledListItem
        component={Link}
        to={`/category/${child.id}`}
        selected={isSelected}
        sx={{ pl: (depth + 1) * 2 }}
      >
        <ListItemIcon sx={{ minWidth: 40 }}>
          <Avatar
            src={categoriesApi.getCategoryImageUrl(child.id)}
            alt={child.name}
            sx={{ width: 32, height: 32 }}
          />
        </ListItemIcon>
        <ListItemText
          primary={child.name}
          primaryTypographyProps={{
            variant: "body2",
            sx: { position: "relative" },
          }}
        />
      </StyledListItem>
    );
  }

  return (
    <Box>
      <Box display="flex" alignItems="center">
        <StyledListItem
          component={Link}
          to={`/category/${category.id}`}
          selected={isSelected}
          sx={{ flex: 1, pr: hasChildren ? 1 : 2 }}
        >
          <ListItemIcon sx={{ minWidth: 40 }}>
            <Avatar
              src={categoriesApi.getCategoryImageUrl(category.id)}
              alt={category.name}
              sx={{ width: 32, height: 32 }}
            />
          </ListItemIcon>
          <ListItemText
            primary={category.name}
            primaryTypographyProps={{
              variant: "body2",
              sx: { position: "relative" },
            }}
          />
        </StyledListItem>
        {hasChildren && (
          <IconButton
            onClick={() => setIsExpanded(!isExpanded)}
            size="small"
            sx={{ mr: 1 }}
          >
            {isExpanded ? <ExpandMoreIcon /> : <ChevronRightIcon />}
          </IconButton>
        )}
      </Box>
      {hasChildren && (
        <Collapse in={isExpanded}>
          <List disablePadding>
            {category.children.map((child) => (
              <CategoryItem
                key={child.id}
                category={category}
                child={child}
                depth={depth + 1}
              />
            ))}
          </List>
        </Collapse>
      )}
    </Box>
  );
};

const Categories = () => {
  const location = useLocation();
  const [categories, setCategories] = useState([]);
  const isAllCategoriesSelected = location.pathname === "/categories";

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await categoriesApi.getAllCategories();
        setCategories(response.data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, []);

  return (
    <Box sx={{ p: 2 }}>
      <List disablePadding>
        {categories.map((category) => (
          <CategoryItem key={category.id} category={category} />
        ))}
      </List>
      <Box sx={{ textAlign: "center", mt: 2 }}>
        <MuiLink
          component={Link}
          to="/categories"
          color="primary"
          underline="hover"
          sx={{
            display: "inline-block",
            position: "relative",
            ...(isAllCategoriesSelected && {
              "&::after": {
                content: '""',
                position: "absolute",
                bottom: 0,
                left: 0,
                width: "100%",
                height: 2,
                backgroundColor: "primary.main",
              },
            }),
          }}
        >
          <Typography variant="body2">Tüm Kategoriler</Typography>
        </MuiLink>
      </Box>
    </Box>
  );
};

export default withErrorBoundary(Categories, {
  resetPath: "/categories",
  resetButtonText: "Retry Loading Categories",
});
