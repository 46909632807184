import React from "react";
import { Link } from "react-router-dom";
import { categoriesApi } from "../../utils/api";
import withErrorBoundary from "../../hoc/withErrorBoundary";
import { Card, CardMedia, CardContent, Typography, Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledCard = styled(Card)(({ theme }) => ({
  position: "relative",
  overflow: "hidden",
  borderRadius: theme.shape.borderRadius * 2,
  boxShadow: theme.shadows[2],
  transition: "all 0.3s ease-in-out",
  transform: "scale(1)",
  "&:hover": {
    boxShadow: theme.shadows[8],
    transform: "scale(1.02)",
    "& .MuiCardMedia-root": {
      transform: "scale(1.1)",
    },
  },
}));

const StyledCardMedia = styled(CardMedia)(({ theme }) => ({
  height: 0,
  paddingTop: "56.25%", // 16:9 aspect ratio
  position: "relative",
  transition: "transform 0.3s ease-in-out",
}));

const Overlay = styled(Box)(({ theme }) => ({
  position: "absolute",
  bottom: 0,
  left: 0,
  right: 0,
  background: "linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0) 100%)",
  padding: theme.spacing(4, 2, 2, 2),
  color: theme.palette.common.white,
}));

const CategoryCard = ({
  category,
  isSubcategory = false,
  parentCategoryId = null,
}) => {
  const linkPath = isSubcategory
    ? `/category/${parentCategoryId}/subcategory/${category.id}`
    : `/category/${category.id}`;

  return (
    <Link to={linkPath} style={{ textDecoration: "none" }}>
      <StyledCard>
        <StyledCardMedia
          image={categoriesApi.getCategoryImageUrl(category.id)}
          title={category.name}
        />
        <Overlay>
          <Typography variant="h5" component="h3" fontWeight="600" gutterBottom>
            {category.name}
          </Typography>
          {category.productCount && (
            <Typography variant="body2" sx={{ color: "grey.300" }}>
              {category.productCount} ürün
            </Typography>
          )}
        </Overlay>
      </StyledCard>
    </Link>
  );
};

export default withErrorBoundary(CategoryCard, {
  resetButtonText: "Reload Category",
});
