import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import withErrorBoundary from "../hoc/withErrorBoundary";

const SearchWrapper = styled("form")(({ theme }) => ({
  position: "relative",
  width: "100%",
}));

const SearchInput = styled(InputBase)(({ theme }) => ({
  width: "100%",
  "& .MuiInputBase-input": {
    padding: theme.spacing(2, 6, 2, 3),
    backgroundColor:
      theme.palette.mode === "dark"
        ? theme.palette.background.paper
        : theme.palette.background.default,
    border: `2px solid ${
      theme.palette.mode === "dark"
        ? theme.palette.grey[700]
        : theme.palette.grey[300]
    }`,
    borderRadius: "9999px",
    transition: theme.transitions.create(["border-color", "background-color"]),
    color: theme.palette.text.primary,
    "&::placeholder": {
      color: theme.palette.text.secondary,
      opacity: 1,
    },
    "&:focus": {
      borderColor: theme.palette.primary.main,
      backgroundColor: theme.palette.background.paper,
    },
  },
}));

const SearchIconButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  right: theme.spacing(1),
  top: "50%",
  transform: "translateY(-50%)",
  padding: theme.spacing(1),
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
  },
  "& .MuiSvgIcon-root": {
    fontSize: "1.25rem",
  },
  transition: theme.transitions.create(["background-color", "transform"]),
  "&:active": {
    transform: "translateY(-50%) scale(0.95)",
  },
}));

const SearchBar = ({ onSearch }) => {
  const [searchTerm, setSearchTerm] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (searchTerm.trim()) {
      onSearch(searchTerm.trim());
    }
  };

  return (
    <SearchWrapper onSubmit={handleSubmit}>
      <SearchInput
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        placeholder="Ürün veya tasarımcı ara..."
        inputProps={{
          "aria-label": "search products and designers",
          maxLength: 100,
        }}
      />
      <SearchIconButton
        type="submit"
        aria-label="search"
        disabled={!searchTerm.trim()}
      >
        <SearchIcon />
      </SearchIconButton>
    </SearchWrapper>
  );
};

export default withErrorBoundary(SearchBar, {
  resetButtonText: "Retry Search",
});
