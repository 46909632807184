import React from 'react';
import { Link } from 'react-router-dom';
import withErrorBoundary from '../hoc/withErrorBoundary';
import {
  Breadcrumbs,
  Link as MuiLink,
  Typography,
  Box,
} from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const BreadcrumbItem = ({ to, children, isLast }) => {
  if (isLast) {
    return (
      <Typography color="text.primary" variant="body2">
        {children}
      </Typography>
    );
  }

  return (
    <MuiLink
      component={Link}
      to={to}
      color="inherit"
      variant="body2"
      sx={{
        '&:hover': {
          color: 'primary.main',
        },
      }}
    >
      {children}
    </MuiLink>
  );
};

const Breadcrumb = ({ items }) => {
  return (
    <Box sx={{ mb: 2 }}>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        {items.map((item, index) => (
          <BreadcrumbItem
            key={index}
            to={item.path}
            isLast={index === items.length - 1}
          >
            {item.label}
          </BreadcrumbItem>
        ))}
      </Breadcrumbs>
    </Box>
  );
};

export default withErrorBoundary(Breadcrumb, {
  resetButtonText: 'Reload Breadcrumb'
}); 