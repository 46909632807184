import React from "react";
import { format } from "date-fns";
import { tr } from "date-fns/locale";
import { postsApi } from "../../utils/api";
import withErrorBoundary from '../../hoc/withErrorBoundary';
import { Card, CardContent, CardMedia, Typography, Box, Button, Chip, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

const POST_TYPE_BADGES = {
  general: { text: "Genel", color: "default" },
  product_news: { text: "Ürün Haberleri", color: "primary" },
  maintenance: { text: "Bakım", color: "warning" },
  promotion: { text: "Kampanya", color: "success" },
};

const StyledCard = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius * 2,
  boxShadow: theme.shadows[2],
  overflow: 'hidden',
  transition: 'box-shadow 0.2s ease-in-out',
  '&:hover': {
    boxShadow: theme.shadows[4],
  },
}));

const ActionButton = styled(Button)(({ theme }) => ({
  minWidth: 'unset',
  padding: theme.spacing(0.5, 1),
  '&.edit-button': {
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      opacity: 0.1,
    },
  },
  '&.delete-button': {
    color: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.light,
      opacity: 0.1,
    },
  },
}));

const PostImage = styled(CardMedia)(({ theme }) => ({
  height: 200,
  objectFit: 'cover',
  borderRadius: theme.shape.borderRadius,
}));

const PostCard = ({ post, onEdit, onDelete }) => {
  return (
    <StyledCard>
      <CardContent>
        <Box display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
          <Box>
            <Typography variant="h5" component="h2" gutterBottom>
              {post.title}
            </Typography>
            <Box display="flex" alignItems="center" gap={1} mt={1}>
              <Chip
                label={POST_TYPE_BADGES[post.type].text}
                color={POST_TYPE_BADGES[post.type].color}
                size="small"
              />
              <Typography variant="body2" color="text.secondary">
                {format(new Date(post.createdAt), "d MMMM yyyy", {
                  locale: tr,
                })}
              </Typography>
            </Box>
          </Box>
          <Box display="flex" gap={1}>
            {onEdit && (
              <ActionButton
                className="edit-button"
                onClick={onEdit}
                variant="text"
              >
                Düzenle
              </ActionButton>
            )}
            {onDelete && (
              <ActionButton
                className="delete-button"
                onClick={onDelete}
                variant="text"
              >
                Sil
              </ActionButton>
            )}
          </Box>
        </Box>

        <Typography variant="body1" color="text.secondary" paragraph sx={{ whiteSpace: 'pre-line' }}>
          {post.content}
        </Typography>

        {post.images && post.images.length > 0 && (
          <Grid container spacing={2} mt={2}>
            {post.images.map((image, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <PostImage
                  component="img"
                  src={postsApi.getPostImageUrl(post.id, index)}
                  alt={`Post image ${index + 1}`}
                />
              </Grid>
            ))}
          </Grid>
        )}
      </CardContent>
    </StyledCard>
  );
};

export default withErrorBoundary(PostCard, {
  resetButtonText: 'Reload Post'
});
