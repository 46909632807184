import React, { useState, useMemo } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { baseTheme, colors } from './theme';
import Layout from './components/Layout';
import AppRoutes from './routes';
import ErrorBoundary from "./components/ErrorBoundary";
import useErrorHandler from "./hooks/useErrorHandler";
import { AuthProvider } from "./contexts/AuthContext";
import { CartProvider } from "./contexts/CartContext";

function AppContent() {
  const { handleError } = useErrorHandler();
  const [darkMode, setDarkMode] = useState(false);

  const theme = useMemo(
    () =>
      createTheme({
        ...baseTheme,
        palette: {
          ...baseTheme.palette,
          mode: darkMode ? 'dark' : 'light',
          primary: {
            main: darkMode ? colors.primary.dark : colors.primary.light,
            light: darkMode ? colors.primary.light : colors.primary.light,
            dark: darkMode ? colors.primary.dark : colors.primary.dark,
            contrastText: darkMode ? colors.text.dark.primary : colors.text.light.primary,
          },
          secondary: {
            main: darkMode ? colors.secondary.dark : colors.secondary.light,
            light: darkMode ? colors.secondary.light : colors.secondary.light,
            dark: darkMode ? colors.secondary.dark : colors.secondary.dark,
            contrastText: darkMode ? colors.text.dark.primary : colors.text.light.primary,
          },
          background: {
            default: darkMode ? colors.background.dark : colors.background.light,
            paper: darkMode ? colors.card.dark : colors.card.light,
          },
          text: {
            primary: darkMode ? colors.text.dark.primary : colors.text.light.primary,
            secondary: darkMode ? colors.text.dark.secondary : colors.text.light.secondary,
            disabled: darkMode ? colors.text.dark.disabled : colors.text.light.disabled,
            hint: darkMode ? colors.text.dark.hint : colors.text.light.hint,
          },
          action: {
            active: darkMode ? colors.text.dark.primary : colors.text.light.primary,
            hover: darkMode ? 'rgba(255, 255, 255, 0.08)' : 'rgba(0, 0, 0, 0.04)',
            selected: darkMode ? 'rgba(255, 255, 255, 0.16)' : 'rgba(0, 0, 0, 0.08)',
            disabled: darkMode ? colors.text.dark.disabled : colors.text.light.disabled,
            disabledBackground: darkMode ? 'rgba(255, 255, 255, 0.12)' : 'rgba(0, 0, 0, 0.12)',
          },
          divider: darkMode ? 'rgba(255, 255, 255, 0.12)' : 'rgba(0, 0, 0, 0.12)',
        },
      }),
    [darkMode]
  );

  const toggleDarkMode = () => {
    setDarkMode((prev) => !prev);
  };

  return (
    <ErrorBoundary onError={handleError}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <AuthProvider>
          <CartProvider>
            <Layout darkMode={darkMode} toggleDarkMode={toggleDarkMode}>
              <AppRoutes />
            </Layout>
          </CartProvider>
        </AuthProvider>
      </ThemeProvider>
    </ErrorBoundary>
  );
}

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;
