import React, { useState, useEffect } from "react";
import { addressApi } from "../../utils/api";
import AddressCard from "../cards/AddressCard";
import AddressForm from "../forms/AddressForm";
import Modal from "../Modal";
import withErrorBoundary from "../../hoc/withErrorBoundary";
import { Box, Typography, Button, Grid, Container } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

const AddressesPanel = () => {
  const [addresses, setAddresses] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMode, setModalMode] = useState("add"); // 'add' or 'edit'
  const [currentAddress, setCurrentAddress] = useState({
    title: "",
    fullName: "",
    phoneNumber: "",
    city: "",
    district: "",
    neighborhood: "",
    fullAddress: "",
    isDefault: false,
  });

  useEffect(() => {
    loadAddresses();
  }, []);

  const loadAddresses = async () => {
    try {
      const response = await addressApi.getAllAddresses();
      setAddresses(response.data);
    } catch (error) {
      console.error("Adresler yüklenirken hata:", error);
    }
  };

  const handleAddSubmit = async (formData) => {
    try {
      await addressApi.createAddress(formData);
      setIsModalOpen(false);
      loadAddresses();
    } catch (error) {
      console.error("Adres eklenirken hata:", error);
    }
  };

  const handleEditSubmit = async (formData) => {
    try {
      await addressApi.updateAddress(formData.id, formData);
      setIsModalOpen(false);
      loadAddresses();
    } catch (error) {
      console.error("Adres güncellenirken hata:", error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await addressApi.deleteAddress(id);
      loadAddresses();
    } catch (error) {
      console.error("Adres silinirken hata:", error);
    }
  };

  const handleEdit = (address) => {
    setCurrentAddress(address);
    setModalMode("edit");
    setIsModalOpen(true);
  };

  const handleAddNew = () => {
    setCurrentAddress({
      title: "",
      fullName: "",
      phoneNumber: "",
      city: "",
      district: "",
      neighborhood: "",
      fullAddress: "",
      isDefault: false,
    });
    setModalMode("add");
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  return (
    <Container>
      <Box
        sx={{
          mb: 4,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h5" component="h2" fontWeight="600">
          Adreslerim
        </Typography>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleAddNew}
        >
          Yeni Adres Ekle
        </Button>
      </Box>

      <Grid container spacing={2}>
        {addresses.map((address) => (
          <Grid item xs={12} md={6} key={address.id}>
            <AddressCard
              address={address}
              onDelete={handleDelete}
              onEdit={handleEdit}
            />
          </Grid>
        ))}
      </Grid>

      <Modal
        isOpen={isModalOpen}
        onClose={handleModalClose}
        title={modalMode === "add" ? "Yeni Adres Ekle" : "Adresi Düzenle"}
      >
        <AddressForm
          mode={modalMode}
          initialData={currentAddress}
          onSubmit={modalMode === "add" ? handleAddSubmit : handleEditSubmit}
          onCancel={handleModalClose}
        />
      </Modal>
    </Container>
  );
};

export default withErrorBoundary(AddressesPanel, {
  resetButtonText: "Reload Addresses",
});
