import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { FaHeart } from "react-icons/fa";
import { wishlistApi } from "../../utils/api";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import withErrorBoundary from "../../hoc/withErrorBoundary";
import { IconButton, Tooltip, Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const WishlistButton = styled(IconButton)(({ theme, size, isfavorite }) => ({
  backgroundColor:
    isfavorite === "true" ? theme.palette.error.main : theme.palette.grey[200],
  color:
    isfavorite === "true"
      ? theme.palette.common.white
      : theme.palette.grey[700],
  padding: size === "large" ? theme.spacing(2) : theme.spacing(1),
  transition: "all 0.2s ease-in-out",
  "&:hover": {
    backgroundColor:
      isfavorite === "true"
        ? theme.palette.error.dark
        : theme.palette.grey[300],
    transform: "scale(1.05)",
  },
  [theme.breakpoints.down("sm")]: {
    padding: size === "large" ? theme.spacing(1.5) : theme.spacing(0.75),
  },
}));

const AddToWishlistButton = ({ product, size = "small", className = "" }) => {
  const [isFavorite, setIsFavorite] = useState(false);
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser) {
      wishlistApi.getWishlist().then((response) => {
        setIsFavorite(
          response.data.some((item) => item.product.id === product.id)
        );
      });
    }
  }, [product.id, currentUser]);

  const handleToggleFavorite = async (e) => {
    e.preventDefault();
    try {
      if (isFavorite) {
        await wishlistApi.removeFromWishlist(product.id);
        setIsFavorite(false);
      } else {
        if (!currentUser) {
          alert("Lütfen giriş yapınız.");
          navigate("/login", { state: { from: `/product/${product.id}` } });
          return;
        }
        await wishlistApi.addToWishlist(product.id);
        setIsFavorite(true);
      }
    } catch (error) {
      console.error("Error toggling wishlist:", error);
    }
  };

  const iconSize = size === "large" ? 20 : 16;

  return (
    <Box className={className}>
      <Tooltip
        title={isFavorite ? "Favorilerden Çıkar" : "Favorilere Ekle"}
        arrow
      >
        <motion.div
          initial={false}
          animate={{ scale: 1 }}
          whileTap={{ scale: 0.9 }}
        >
          <WishlistButton
            onClick={handleToggleFavorite}
            size={size}
            isfavorite={isFavorite.toString()}
          >
            <FaHeart size={iconSize} />
          </WishlistButton>
        </motion.div>
      </Tooltip>
    </Box>
  );
};

export default withErrorBoundary(AddToWishlistButton, {
  resetButtonText: "Retry",
});
