import React from 'react';
import { Alert, Button, Card, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { isDevelopment } from '../utils/environment';

const ErrorContainer = styled(Card)(({ theme }) => ({
  padding: theme.spacing(3),
  margin: theme.spacing(2),
  maxWidth: 800,
  marginLeft: 'auto',
  marginRight: 'auto',
}));

const ErrorMessage = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  color: theme.palette.error.main,
}));

const ErrorDetails = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(2),
  padding: theme.spacing(2),
  backgroundColor: theme.palette.grey[100],
  borderRadius: theme.shape.borderRadius,
  fontFamily: 'monospace',
  whiteSpace: 'pre-wrap',
  overflowX: 'auto',
}));

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      hasError: false,
      error: null,
      errorInfo: null
    };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error,
      errorInfo
    });

    // Log error to your error tracking service here
    console.error('Error caught by ErrorBoundary:', error, errorInfo);
  }

  handleReset = () => {
    this.setState({
      hasError: false,
      error: null,
      errorInfo: null
    });
    
    // If onReset prop is provided, call it
    if (this.props.onReset) {
      this.props.onReset();
    }
    
    // If resetPath prop is provided, navigate to it
    if (this.props.resetPath) {
      window.location.href = this.props.resetPath;
    }
  };

  getUserFriendlyMessage(error) {
    if (!error) return 'An unexpected error occurred';

    // Handle API errors
    if (error.response) {
      const status = error.response.status;
      const message = error.response.data?.message;

      // If we have a specific message from the API, use it
      if (message) return message;

      // Otherwise, use default messages based on status code
      switch (status) {
        case 400:
          return 'The request was invalid. Please check your input and try again.';
        case 401:
          return 'Your session has expired. Please log in again.';
        case 403:
          return 'You do not have permission to perform this action.';
        case 404:
          return 'The requested resource was not found.';
        case 409:
          return 'This action conflicts with the current state.';
        case 422:
          return 'The provided data was invalid.';
        case 429:
          return 'Too many requests. Please try again later.';
        case 500:
          return 'An internal server error occurred. Please try again later.';
        default:
          return 'An unexpected error occurred. Please try again later.';
      }
    }

    // Handle network errors
    if (error.request) {
      return 'Unable to connect to the server. Please check your internet connection.';
    }

    // Handle other errors
    return error.message || 'An unexpected error occurred';
  }

  render() {
    if (!this.state.hasError) {
      return this.props.children;
    }

    const { error, errorInfo } = this.state;
    const userMessage = this.getUserFriendlyMessage(error);

    return (
      <ErrorContainer>
        <Alert severity="error" sx={{ mb: 2 }}>
          {userMessage}
        </Alert>

        {isDevelopment && (
          <>
            <ErrorMessage variant="h6">
              Technical Details:
            </ErrorMessage>
            <ErrorDetails component="pre">
              {error?.toString()}
              {'\n'}
              {errorInfo?.componentStack}
            </ErrorDetails>
          </>
        )}

        <Button 
          variant="contained" 
          color="primary" 
          onClick={this.handleReset}
          sx={{ mt: 2 }}
        >
          {this.props.resetButtonText || 'Try Again'}
        </Button>
      </ErrorContainer>
    );
  }
}

export default ErrorBoundary; 