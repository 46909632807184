import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import SearchBar from "./SearchBar";
import Categories from "./Categories";
import ProfileDropdown from "../components/ProfileDropdown";
import { useAuth } from "../contexts/AuthContext";
import { useCart } from "../contexts/CartContext";
import { useTheme } from "@mui/material/styles";
import withErrorBoundary from "../hoc/withErrorBoundary";
import {
  AppBar,
  Toolbar,
  Box,
  Button,
  IconButton,
  Badge,
  Container,
  Typography,
  Popper,
  Paper,
  Grow,
  Hidden,
  Menu,
  MenuItem,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  Home as HomeIcon,
  ShoppingCart as CartIcon,
  Category as CategoryIcon,
  Palette as PaletteIcon,
  Person as PersonIcon,
  Menu as MenuIcon,
  LightMode as LightModeIcon,
  DarkMode as DarkModeIcon,
} from "@mui/icons-material";

const LogoText = styled(Typography)(({ theme }) => ({
  fontFamily: theme.typography.logo.fontFamily,
  fontSize: "4rem",
  textDecoration: "none",
  "& .primary": {
    color:
      theme.palette.mode === "dark"
        ? theme.palette.primary.light
        : theme.palette.primary.main,
    borderBottom: `4px solid ${
      theme.palette.mode === "dark"
        ? theme.palette.secondary.light
        : theme.palette.secondary.main
    }`,
  },
  "& .secondary": {
    color:
      theme.palette.mode === "dark"
        ? theme.palette.secondary.light
        : theme.palette.secondary.main,
    borderBottom: `4px solid ${
      theme.palette.mode === "dark"
        ? theme.palette.primary.light
        : theme.palette.primary.main
    }`,
  },
}));

const SloganText = styled(Typography)(({ theme }) => ({
  fontFamily: theme.typography.logo.fontFamily,
  fontSize: "0.75rem",
}));

const NavButton = styled(Button)(({ theme }) => ({
  color: theme.palette.common.white,
  backgroundColor: theme.palette.primary.main,
  padding: theme.spacing(1.5, 2.5),
  borderRadius: theme.shape.borderRadius,
  textTransform: "none",
  fontSize: "1rem",
  fontWeight: 600,
  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
  },
  "& .MuiButton-startIcon": {
    marginRight: theme.spacing(1),
  },
}));

function Header({ darkMode, toggleDarkMode }) {
  const navigate = useNavigate();
  const theme = useTheme();
  const [categoriesAnchorEl, setCategoriesAnchorEl] = useState(null);
  const [profileAnchorEl, setProfileAnchorEl] = useState(null);
  const [isCategoriesOpen, setIsCategoriesOpen] = useState(false);
  const [mobileMenuAnchorEl, setMobileMenuAnchorEl] = useState(null);
  const { currentUser } = useAuth();
  const { getItemCount } = useCart();
  const handleMobileMenuOpen = (event) => {
    setMobileMenuAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMenuAnchorEl(null);
  };

  const handleCategoriesClick = (event) => {
    if (event) {
      setCategoriesAnchorEl(event.currentTarget);
      setIsCategoriesOpen(!isCategoriesOpen);
    } else {
      // Mobile menu case
      navigate("/categories");
      handleMobileMenuClose();
    }
  };

  const handleCategoriesEnter = (event) => {
    setCategoriesAnchorEl(event.currentTarget);
    setIsCategoriesOpen(true);
  };

  const handleCategoriesLeave = (event) => {
    const relatedTarget = event.relatedTarget;
    const isMovingToPopper = relatedTarget?.closest(".categories-popper");

    if (!isMovingToPopper) {
      setIsCategoriesOpen(false);
    }
  };

  const handlePopperLeave = (event) => {
    const relatedTarget = event.relatedTarget;
    const isMovingToButton = relatedTarget?.closest(".categories-button");

    if (!isMovingToButton) {
      setIsCategoriesOpen(false);
    }
  };

  const handleSearch = (searchTerm) => {
    navigate(`/products?search=${encodeURIComponent(searchTerm)}`);
  };

  return (
    <AppBar
      position="sticky"
      sx={{
        backgroundColor: "background.paper",
        borderBottom: 1,
        borderColor: "divider",
      }}
    >
      <Container maxWidth={false}>
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            py: 2,
            gap: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flexShrink: 0,
            }}
          >
            <LogoText component={Link} to="/">
              <span className="primary">tasar</span>
              <span className="secondary">al</span>
            </LogoText>
            <Box sx={{ display: "flex", gap: 0.5 }}>
              <SloganText variant="caption" color="primary.main">
                Türkiye'nin En Büyük
              </SloganText>
              <SloganText variant="caption" color="secondary.main">
                Tasarım
              </SloganText>
              <SloganText variant="caption" color="primary.main">
                Pazarı
              </SloganText>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              flex: 1,
              justifyContent: "center",
              maxWidth: { xs: "100%", md: "600px" },
            }}
          >
            <SearchBar onSearch={handleSearch} />
          </Box>

          <Box
            sx={{
              display: "flex",
              gap: 2,
              alignItems: "center",
              ml: 2,
            }}
          >
            <Hidden mdDown>
              <NavButton component={Link} to="/" startIcon={<HomeIcon />}>
                Ana Sayfa
              </NavButton>

              <Box>
                <NavButton
                  className="categories-button"
                  onClick={handleCategoriesClick}
                  onMouseEnter={handleCategoriesEnter}
                  onMouseLeave={handleCategoriesLeave}
                  startIcon={<CategoryIcon />}
                  aria-haspopup="true"
                  aria-expanded={isCategoriesOpen}
                >
                  Kategoriler
                </NavButton>
                <Popper
                  className="categories-popper"
                  open={isCategoriesOpen}
                  anchorEl={categoriesAnchorEl}
                  placement="bottom-start"
                  transition
                  onMouseLeave={handlePopperLeave}
                  sx={{
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                  }}
                >
                  {({ TransitionProps }) => (
                    <Grow
                      {...TransitionProps}
                      timeout={250}
                      style={{ transformOrigin: "0 0 0" }}
                    >
                      <Paper
                        elevation={4}
                        sx={{
                          width: 280,
                          mt: 0.5,
                          border: 1,
                          borderColor: "divider",
                          overflow: "hidden",
                          bgcolor: "background.paper",
                        }}
                      >
                        <Categories />
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </Box>
            </Hidden>

            <IconButton
              color="primary"
              onClick={() => navigate("/cart")}
              sx={{
                p: 1.25,
                backgroundColor: "action.hover",
                "&:hover": {
                  backgroundColor: "action.selected",
                },
                color: "text.primary",
              }}
            >
              <Badge badgeContent={getItemCount()} color="secondary">
                <CartIcon />
              </Badge>
            </IconButton>

            {currentUser ? (
              <Box>
                <IconButton
                  onClick={(e) => {
                    setProfileAnchorEl(e.currentTarget);
                  }}
                  color="primary"
                  sx={{
                    p: 1.25,
                    backgroundColor: "action.hover",
                    "&:hover": {
                      backgroundColor: "action.selected",
                    },
                    color: "text.primary",
                  }}
                >
                  <PersonIcon />
                </IconButton>
                {profileAnchorEl && (
                  <ProfileDropdown
                    isOpen={Boolean(profileAnchorEl)}
                    setIsOpen={(isOpen) =>
                      setProfileAnchorEl(isOpen ? profileAnchorEl : null)
                    }
                    anchorEl={profileAnchorEl}
                    darkMode={darkMode}
                  />
                )}
              </Box>
            ) : (
              <Hidden lgDown>
                <NavButton
                  component={Link}
                  to="/login"
                  startIcon={<PersonIcon />}
                >
                  Giriş Yap
                </NavButton>
              </Hidden>
            )}

            <IconButton
              onClick={toggleDarkMode}
              color="primary"
              sx={{
                p: 1.25,
                backgroundColor: "action.hover",
                "&:hover": {
                  backgroundColor: "action.selected",
                },
                color: "text.primary",
              }}
            >
              {darkMode ? <LightModeIcon /> : <DarkModeIcon />}
            </IconButton>

            <Hidden mdUp>
              <IconButton
                edge="end"
                color="inherit"
                onClick={handleMobileMenuOpen}
              >
                <MenuIcon />
              </IconButton>
              <Menu
                anchorEl={mobileMenuAnchorEl}
                open={Boolean(mobileMenuAnchorEl)}
                onClose={handleMobileMenuClose}
              >
                <MenuItem
                  component={Link}
                  to="/"
                  onClick={handleMobileMenuClose}
                >
                  <HomeIcon sx={{ mr: 1 }} /> Ana Sayfa
                </MenuItem>
                <MenuItem onClick={() => handleCategoriesClick()}>
                  <CategoryIcon sx={{ mr: 1 }} /> Kategoriler
                </MenuItem>
                {!currentUser && (
                  <MenuItem
                    component={Link}
                    to="/login"
                    onClick={handleMobileMenuClose}
                  >
                    <PersonIcon sx={{ mr: 1 }} /> Giriş
                  </MenuItem>
                )}
              </Menu>
            </Hidden>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default withErrorBoundary(Header);
