import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import {
  Box,
  Container,
  Typography,
  Grid,
  CircularProgress,
  Alert,
  useTheme,
} from "@mui/material";
import ProductList from "../components/ProductList";
import { productsApi, categoriesApi } from "../utils/api";
import CategoryCard from "../components/cards/CategoryCard";
import Breadcrumb from "../components/Breadcrumb";
import withErrorBoundary from "../hoc/withErrorBoundary";

const CategoryChildren = ({ children, parentCategory }) => {
  if (!children || children.length === 0) return null;

  return (
    <Box sx={{ mb: 8 }}>
      <Typography variant="h4" component="h2" sx={{ mb: 3, fontWeight: 600 }}>
        Alt Kategoriler
      </Typography>
      <Grid container spacing={3}>
        {children.map((child) => (
          <Grid item xs={12} sm={6} lg={4} xl={3} key={child.id}>
            <CategoryCard
              category={child}
              isSubcategory={true}
              parentCategory={parentCategory}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

const CategoryPage = () => {
  const { id } = useParams();
  const theme = useTheme();
  const [categoryData, setCategoryData] = useState({
    category: null,
    ancestors: [],
    products: [],
    loading: true,
    error: null,
  });

  useEffect(() => {
    const fetchCategoryData = async () => {
      try {
        // Fetch category data from backend
        const categoryResponse = await categoriesApi.getCategoryById(id);
        const { ancestors, ...category } = categoryResponse.data;

        let products = category.products;
        // Gather all products from subcategories
        products = [
          ...products,
          ...category.children.map((child) => child.products),
        ];
        products = products.flat();

        setCategoryData({
          category,
          ancestors,
          products: products,
          loading: false,
          error: null,
        });
      } catch (error) {
        console.error("Error fetching category data:", error);
        setCategoryData((prev) => ({
          ...prev,
          loading: false,
          error: "Kategori yüklenirken bir hata oluştu.",
        }));
      }
    };

    setCategoryData((prev) => ({ ...prev, loading: true }));
    fetchCategoryData();
  }, [id]);

  const { category, ancestors, products, loading, error } = categoryData;

  if (loading) {
    return (
      <Container
        maxWidth="lg"
        sx={{ py: 8, display: "flex", justifyContent: "center" }}
      >
        <CircularProgress />
      </Container>
    );
  }

  if (error) {
    return (
      <Container maxWidth="lg" sx={{ py: 8 }}>
        <Alert severity="error" sx={{ mb: 4 }}>
          {error}
        </Alert>
      </Container>
    );
  }

  if (!category) {
    return (
      <Container maxWidth="lg" sx={{ py: 8 }}>
        <Alert severity="info" sx={{ mb: 4 }}>
          Kategori bulunamadı.
        </Alert>
      </Container>
    );
  }

  const breadcrumbItems = [
    { path: "/categories", label: "Kategoriler" },
    ...(ancestors?.map((ancestor) => ({
      path: `/category/${ancestor.id}`,
      label: ancestor.name,
    })) || []),
    { path: `/category/${category.id}`, label: category.name },
  ];

  return (
    <Container
      sx={{
        maxWidth: "90% !important",
      }}
    >
      <Box sx={{ mb: 6 }}>
        <Breadcrumb items={breadcrumbItems} />
        <Typography
          variant="h2"
          component="h1"
          sx={{ fontWeight: "bold", mb: 2 }}
        >
          {category.name}
        </Typography>
        {category.description && (
          <Typography variant="body1" color="text.secondary" sx={{ mt: 1 }}>
            {category.description}
          </Typography>
        )}
      </Box>

      <CategoryChildren
        children={category.children}
        parentCategory={category}
      />

      {products.length > 0 && (
        <Box sx={{ mt: 6 }}>
          <Typography
            variant="h4"
            component="h2"
            sx={{ mb: 3, fontWeight: 600 }}
          >
            {category.children?.length > 0 ? "Öne Çıkan Ürünler" : "Ürünler"}
          </Typography>
          <ProductList products={products} />
        </Box>
      )}
    </Container>
  );
};

export default withErrorBoundary(CategoryPage, {
  resetButtonText: "Reload Category Page",
});
