import React from "react";
import { useCart } from "../contexts/CartContext";
import { useAuth } from "../contexts/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import { productsApi } from "../utils/api";
import { ordersApi } from "../utils/api";
import {
  Box,
  Container,
  Typography,
  Button,
  Paper,
  Grid,
  Divider,
  useTheme,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import CartItemCard from "../components/cards/CartItemCard";
import withErrorBoundary from "../hoc/withErrorBoundary";

const CartPage = () => {
  const { currentUser } = useAuth();
  const {
    cart,
    removeFromCart,
    updateQuantity,
    clearCart,
    getCartTotal,
    getItemCount,
  } = useCart();
  const theme = useTheme();
  const navigate = useNavigate();

  if (cart.length === 0) {
    return (
      <Container maxWidth="lg" sx={{ py: 8 }}>
        <Box sx={{ textAlign: "center" }}>
          <Typography
            variant="h4"
            component="h2"
            sx={{ mb: 2, fontWeight: "bold" }}
          >
            Sepetiniz Boş
          </Typography>
          <Typography variant="body1" sx={{ mb: 4 }}>
            Sepetinizde henüz ürün bulunmamaktadır.
          </Typography>
          <Button
            component={Link}
            to="/"
            variant="contained"
            color="primary"
            sx={{
              px: 4,
              py: 1,
              "&:hover": {
                opacity: 0.9,
              },
            }}
          >
            Alışverişe Başla
          </Button>
        </Box>
      </Container>
    );
  }

  const handleOrder = async () => {
    try {
      if (!currentUser) {
        alert("Lütfen giriş yapınız.");
        navigate("/login", { state: { from: "/cart" } });
        return;
      }

      const response = await ordersApi.createOrder(cart);

      if (response.success) {
        clearCart();
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Container maxWidth="lg" sx={{ py: 8 }}>
      <Grid container spacing={4}>
        {/* Cart Items */}
        <Grid item xs={12} lg={8}>
          <Paper
            elevation={2}
            sx={{
              p: 4,
              borderRadius: 2,
              bgcolor: "background.paper",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 4,
              }}
            >
              <Typography
                variant="h4"
                component="h2"
                sx={{ fontWeight: "bold" }}
              >
                Sepetim ({getItemCount()} Ürün)
              </Typography>
              <Button
                startIcon={<DeleteIcon />}
                color="error"
                onClick={clearCart}
                sx={{
                  "&:hover": {
                    bgcolor: "error.light",
                    color: "white",
                  },
                }}
              >
                Sepeti Temizle
              </Button>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              {cart.map((item) => (
                <CartItemCard
                  key={item.id}
                  item={item}
                  onUpdateQuantity={updateQuantity}
                  onRemove={removeFromCart}
                />
              ))}
            </Box>
          </Paper>
        </Grid>

        {/* Order Summary */}
        <Grid item xs={12} lg={4}>
          <Paper
            elevation={2}
            sx={{
              p: 4,
              borderRadius: 2,
              bgcolor: "background.paper",
              position: "sticky",
              top: theme.spacing(2),
            }}
          >
            <Typography
              variant="h5"
              component="h2"
              sx={{ fontWeight: "bold", mb: 3 }}
            >
              Sipariş Özeti
            </Typography>
            <Box
              sx={{ display: "flex", flexDirection: "column", gap: 2, mb: 3 }}
            >
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography variant="body1">Ara Toplam</Typography>
                <Typography variant="body1">
                  {getCartTotal().toFixed(2)} TL
                </Typography>
              </Box>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography variant="body1">Kargo</Typography>
                <Typography variant="body1">Ücretsiz</Typography>
              </Box>
              <Divider sx={{ my: 1 }} />
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                  Toplam
                </Typography>
                <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                  {getCartTotal().toFixed(2)} TL
                </Typography>
              </Box>
            </Box>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleOrder}
              sx={{
                py: 1.5,
                "&:hover": {
                  opacity: 0.9,
                },
              }}
            >
              Siparişi Tamamla
            </Button>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default withErrorBoundary(CartPage, {
  resetButtonText: "Reload Cart Page",
});
